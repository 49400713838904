import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  BackButton,
  Error,
  Loading,
  MaterialLotTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function MaterialChildLots
 * @description Display a collections or a list of MaterialChildLots from database
 */

export default function MaterialChildLots({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const params = useParams();

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.materialStockLotAll({
          page,
          size,
          ancestor: params.id,
        }),
      );
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size]);

  useEffect(() => {
    if (materialStockLot) {
      setTotal(materialStockLot.total);
    }

    return () => {};
  }, [materialStockLot]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot.isLoading) {
    return <Loading />;
  }
  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <MaterialLotTable
            imsSetting={information?.setting?.ims}
            materialStockLot={materialStockLot}
            multiMaterial
            page={page}
            size={size}
            setPage={setPage}
            setSize={setSize}
            total={total}
            showActionButton
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

MaterialChildLots.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialChildLots.defaultProps = {
  title: '',
  subtitle: '',
};
