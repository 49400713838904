import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

const QuotationProductTable = ({
  quotation,
  showAdditonalPrice = true,
  showPrice = true,
}) => {
  const totalPriceBeforeDiscount = _.sumBy(
    quotation?.processes,
    (each) => each?.quantity * each?.price_per_unit,
  );
  const totalPrice =
    totalPriceBeforeDiscount -
    (quotation?.discount || 0) +
    (quotation?.express_fee || 0);
  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สินค้า/วัตถุดิบที่สั่ง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวน</div>
                </TableCell>
                {showPrice && (
                  <TableCell>
                    <div className="font-bold">ราคาต่อหน่วย</div>
                  </TableCell>
                )}
                {showPrice && (
                  <TableCell>
                    <div className="font-bold">ราคา</div>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(quotation?.processes) ? (
                _.map(quotation?.processes, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      {row?.produce_material_instead && (
                        <div>
                          {row?.product_as_material?.type_code}{' '}
                          {row?.product_as_material?.name}
                        </div>
                      )}
                      {row?.produce_base_project_instead && (
                        <div> {row?.base_project?.name}</div>
                      )}
                      {!row?.produce_base_project_instead &&
                        !row?.produce_material_instead && (
                          <div>
                            {row?.product?.type_code} {row?.product?.name}
                          </div>
                        )}
                      <div>{row?.remark}</div>
                    </TableCell>
                    <TableCell>
                      {row?.additional_unit && (
                        <div>
                          {row?.quantity} {row?.additional_unit}
                        </div>
                      )}
                      {!row?.additional_unit && row?.produce_material_instead && (
                        <div>
                          <MultiUnitProdOrMatBox
                            foundMaterial={row?.product_as_material}
                            quantity={row?.quantity}
                          />
                        </div>
                      )}
                      {!row?.additional_unit &&
                        row?.produce_base_project_instead && (
                          <div> {row?.quantity} รายการ</div>
                        )}
                      {!row?.additional_unit &&
                        !row?.produce_base_project_instead &&
                        !row?.produce_material_instead && (
                          <div>
                            <MultiUnitProdOrMatBox
                              foundMaterial={row?.product}
                              quantity={row?.quantity}
                            />
                          </div>
                        )}
                    </TableCell>{' '}
                    {showPrice && (
                      <TableCell>
                        {currencyFormatter.format(row?.price_per_unit)} บาท
                        {quotation?.express && (
                          <div className="text-sm text-red-500">
                            + {row?.added_percent} %
                            <div className="text-xs">
                              เดิม{' '}
                              {currencyFormatter.format(
                                (row?.price_per_unit * 100) /
                                  (100 + (row?.added_percent || 0)),
                              )}{' '}
                              บาท
                            </div>
                          </div>
                        )}
                      </TableCell>
                    )}
                    {showPrice && (
                      <TableCell>
                        {currencyFormatter.format(
                          row?.quantity * row?.price_per_unit,
                        )}{' '}
                        บาท
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              {showPrice && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <div className="font-bold">รวม</div>
                  </TableCell>

                  <TableCell>
                    <div className="font-bold">
                      {currencyFormatter.format(totalPriceBeforeDiscount)} บาท
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {showAdditonalPrice && (
                <TableRow>
                  <TableCell colSpan={4}>
                    ค่าธรรมเนียมสำหรับการผลิตด่วน
                  </TableCell>
                  <TableCell>
                    {quotation?.express
                      ? currencyFormatter.format(quotation?.express_fee || 0)
                      : '-'}{' '}
                    บาท
                  </TableCell>
                </TableRow>
              )}{' '}
              {showAdditonalPrice && (
                <TableRow>
                  <TableCell colSpan={4}>ส่วนลด</TableCell>
                  <TableCell>
                    {currencyFormatter.format(quotation?.discount || 0)} บาท
                  </TableCell>
                </TableRow>
              )}
              {showAdditonalPrice && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <div className="font-bold">ราคารวม</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">
                      {currencyFormatter.format(totalPrice)} บาท
                    </div>
                  </TableCell>
                </TableRow>
              )}{' '}
              {showAdditonalPrice && (
                <TableRow>
                  <TableCell colSpan={4}>
                    ราคาไม่รวมภาษีมูลค่าเพิ่ม (VAT 7%)
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format((totalPrice * 93) / 100)} บาท
                  </TableCell>
                </TableRow>
              )}
              {showAdditonalPrice && (
                <TableRow>
                  <TableCell colSpan={4}>ภาษีมูลค่าเพิ่ม (VAT 7%)</TableCell>
                  <TableCell>
                    {currencyFormatter.format((totalPrice * 7) / 100)} บาท
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default QuotationProductTable;

QuotationProductTable.propTypes = {
  quotation: PropTypes.object,
};
