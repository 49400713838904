import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  ShippingRoundForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function CreateShippingRound
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function CreateShippingRound({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, handleSubmit } = useForm();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleSubmitFunction = async (data) => {
    try {
      await dispatch(actions.shippingRoundCreate(data));
      Swal.fire({
        icon: 'success',
        title: 'สร้างรอบจัดส่งสำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `เกิดข้อผิดพลาด ${error?.message}`,
      });
    }
  };

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <div className="my-2">
        <form onSubmit={handleSubmit(handleSubmitFunction)}>
          <Card>
            <CardContent>
              <ShippingRoundForm control={control} />
            </CardContent>
          </Card>
          <div className="flex justify-end mt-2">
            <Button type="submit" variant="contained" color="primary">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

CreateShippingRound.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateShippingRound.defaultProps = {
  title: '',
  subtitle: '',
};
