import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const ShippingRoundForm = ({ shippingRound, control }) => (
  <div className="flex flex-wrap">
    <div className="w-full py-2 px-1">
      <Controller
        name="name"
        defaultValue={shippingRound?.name || ''}
        control={control}
        render={({ field }) => (
          <TextField {...field} fullWidth size="small" label="ชื่อรอบจัดส่ง" />
        )}
      />
    </div>
    <div className="w-full py-2 px-1">
      <Controller
        name="description"
        defaultValue={shippingRound?.description || ''}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            size="small"
            label="รายละเอียด"
            multiline
            rows={3}
          />
        )}
      />
    </div>
  </div>
);

export default ShippingRoundForm;

ShippingRoundForm.propTypes = {
  shippingRound: PropTypes.object,
  control: PropTypes.object,
};
