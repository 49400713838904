import api from '../../../configs/api';
import {
  STEP_LOG_ALL,
  STEP_LOG_ERROR,
  STEP_LOG_GET,
  STEP_LOG_LOADING,
} from '../types';

export const stepLogAll = ({
  name = '',
  size = '',
  page = 1,
  status = '',
  department = '',
  completed = false,
  all = false,
  startDate = '',
  endDate = '',
  deleted = false,
  incompleted = true,
  assigned = '',
  employee = '',
  forceWithoutCache = '',
  productCode = '',
  assigner = '',
  assignerDepartment = '',
}) => async (dispatch) => {
  try {
    const { data, status: apiStatus } = await api.get(
      `${process.env.REACT_APP_API_URL}/step-log?productName=${name}&size=${size}&page=${page}&status=${status}&department=${department}&completed=${completed}&all=${all}&startDate=${startDate}&endDate=${endDate}&deleted=${deleted}&incompleted=${incompleted}&assigned=${assigned}&employee=${employee}&forceWithoutCache=${forceWithoutCache}&productCode=${productCode}&assigner=${assigner}&assignerDepartment=${assignerDepartment}`,
    );
    if (apiStatus === 200) {
      dispatch({ type: STEP_LOG_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: STEP_LOG_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const stepLogGet = (id, { fetchMeasurement = false } = {}) => async (
  dispatch,
) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/step-log/${id}?all=true&fetchMeasurement=${fetchMeasurement}`,
    );
    if (status === 200) {
      dispatch({ type: STEP_LOG_GET, payload: data });
    }
    if (status === 404) {
      dispatch({ type: STEP_LOG_ERROR, payload: { message: '' } });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: STEP_LOG_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const stepLogStateSet = (data) => async (dispatch) => {
  dispatch({ type: STEP_LOG_ALL, payload: data });
};

export const stepLogStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: STEP_LOG_GET, payload: data });
};

export const stepLogReset = () => async (dispatch) => {
  dispatch({ type: STEP_LOG_LOADING });
};
