import React, { useState } from 'react';
import { Edit } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent, Dialog } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const CardDeliveryInProcess = ({ selectedProcess, flex = false }) => {
  console.log('selectedProcess', selectedProcess);
  const deliveryData = selectedProcess?.delivery;
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <React.Fragment>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <div className="p-2">
          <img src={deliveryData?.image?.url} className="w-full" />
        </div>
      </Dialog>
      <Card className="my-1">
        <CardContent>
          <div className="flex justify-between items-center">
            <h4 className="font-bold font-display">การจัดส่ง</h4>
            <Link
              to={
                selectedProcess?.completed || selectedProcess?.deleted
                  ? '#'
                  : `/mms/process/update-delivery/${selectedProcess?._id}`
              }
            >
              <Button
                startIcon={<Edit size={16} />}
                disabled={
                  selectedProcess?.completed || selectedProcess?.deleted
                }
              >
                แก้ไข
              </Button>
            </Link>
          </div>
          <div className="my-2">
            {deliveryData?.delivered ? (
              <div className="flex gap-1 items-center">
                <div>
                  <i className="fas fa-check-circle text-green-500 text-lg"></i>
                </div>
                <div>จัดส่งแล้ว</div>
              </div>
            ) : (
              <div className="flex gap-1 items-center">
                <div>
                  <i className="fas fa-times-circle text-red-500 text-lg"></i>
                </div>
                <div>ยังไม่จัดส่ง</div>
              </div>
            )}
          </div>
          <div className={`${flex ? 'flex flex-wrap' : ''}`}>
            <div className={`${flex ? 'lg:w-1/2 w-full' : ''} my-1 mt-2`}>
              วิธีจัดส่ง : {deliveryData?.delivery_method?.name}
            </div>{' '}
            <div className={`${flex ? 'lg:w-1/2 w-full' : ''} my-1 `}>
              รอบการจัดส่ง : {deliveryData?.shipping_round?.name || '-'}
            </div>
            <div className={`${flex ? 'lg:w-1/2 w-full' : ''} my-1 `}>
              วันที่รับสินค้า :{' '}
              {dayjs(deliveryData?.delivery_date).format('D MMM BBBB')}
            </div>
            <div className={`${flex ? 'lg:w-1/2 w-full' : ''} my-1 `}>
              หมายเหตุ : {deliveryData?.delivery_remark || '-'}
            </div>
          </div>

          <div>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setDialogOpen(true)}
            >
              หลักฐาน
            </Button>
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default CardDeliveryInProcess;

CardDeliveryInProcess.propTypes = {
  selectedProcess: PropTypes.object,
};
