import React, { useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

dayjs.extend(BuddhistEra);

export const ManufacturingOrderInfoForm = ({
  Controller,
  control,
  errors,
  manufacturingOrder,
  watch,
  paymentType,
  customer,
  information,
}) => {
  const [reload, setReload] = useState(false);

  const renderAttachCustomer = () => (
    <div>
      <div className="flex flex-row flex-wrap ">
        <div className="w-full py-1 ">
          {!_.isEmpty(customer?.rows) && (
            <Controller
              name="customer"
              control={control}
              defaultValue={manufacturingOrder?.customer || customer?.rows?.[0]}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                    setReload(!reload);
                  }}
                  options={_.map(customer?.rows, (e, i) => ({
                    ...e,
                    index: i + 1,
                  }))}
                  getOptionLabel={(option) =>
                    `${option?.index} ${option?.type_code || ''} | ${
                      option?.name
                    }`
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="เลือกลูกค้า" />
                  )}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );

  // Components Rendering
  const renderOrderInfo = () => (
    <div>
      <div className="flex flex-row flex-wrap ">
        <div className="w-full lg:w-1/2 px-1 py-1">
          <div className="py-1">วันที่สั่งผลิต </div>
          <Controller
            name="start_date"
            control={control}
            defaultValue={
              manufacturingOrder
                ? dayjs(manufacturingOrder?.start_date)
                    .locale('th')
                    .format('YYYY-MM-DD')
                : dayjs(new Date()).locale('th').format('YYYY-MM-DD')
            }
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                type="date"
                fullWidth
                size={'small'}
                helperText={errors.start_date && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>{' '}
        {information?.setting?.mms?.usingAsDefault?.showExpectedDate && (
          <div className="lg:w-1/2 w-full  px-1 py-1">
            <div className="py-1">วันที่กำหนดจัดส่ง </div>
            <Controller
              name="expected_date"
              control={control}
              defaultValue={
                manufacturingOrder
                  ? dayjs(manufacturingOrder?.expected_date)
                      .locale('th')
                      .format('YYYY-MM-DD')
                  : dayjs(new Date()).locale('th').format('YYYY-MM-DD')
              }
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  fullWidth
                  size={'small'}
                  helperText={errors.expected_date && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        )}{' '}
        <div className="w-full px-1 py-1">
          <Controller
            name="attachCustomer"
            control={control}
            value={_.isObject(manufacturingOrder?.customer) || false}
            render={({ field }) => (
              <FormControl disabled={watch('attachOrder')}>
                <FormControlLabel
                  label="ระบุลูกค้า"
                  control={<Checkbox {...field} checked={field?.value} />}
                />
              </FormControl>
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          {watch('attachCustomer') === true ? renderAttachCustomer() : ''}
        </div>
        {information?.setting?.mms?.manufacturingOrder?.showQuotation && (
          <div className="w-full lg:w-1/2  px-1 py-1">
            <Controller
              name="quotation_number"
              control={control}
              defaultValue={manufacturingOrder?.quotation_number}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size={'small'}
                  label="เลขที่ใบเสนอราคา"
                  helperText={errors.quotation_number && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        )}{' '}
        {information?.setting?.mms?.manufacturingOrder?.showPaymentMethod && (
          <div className="w-full lg:w-1/2  px-1 py-1">
            <Controller
              name="payment_method"
              control={control}
              defaultValue={manufacturingOrder?.payment_method?._id}
              rules={{ required: false }}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>ช่องทางการชำระเงิน</InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    size={'small'}
                    label="ช่องทางการชำระเงิน"
                  >
                    {_.map(paymentType?.rows, (row, index) => (
                      <MenuItem key={index} value={row?._id}>
                        {row?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </div>
        )}{' '}
        {information?.setting?.mms?.manufacturingOrder?.showPrice && (
          <div className="w-full lg:w-1/2  px-1 py-1">
            <Controller
              name="discount"
              control={control}
              defaultValue={manufacturingOrder?.discount}
              rules={{ required: false, min: 0 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size={'small'}
                  label="ส่วนลด (บาท)"
                  helperText={errors.discount && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        )}{' '}
        {watch('express') && (
          <div className="w-full lg:w-1/2  px-1 py-1">
            <Controller
              name="discount"
              control={control}
              defaultValue={manufacturingOrder?.express_fee}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size={'small'}
                  label="ค่าธรรมเนียมเพิ่มเติม สำหรับการผลิตด่วน"
                />
              )}
            />
          </div>
        )}
        {information?.setting?.mms?.manufacturingOrder?.showPO && (
          <div className="w-full lg:w-1/2  px-1 py-1">
            <Controller
              name="po_number"
              control={control}
              defaultValue={manufacturingOrder?.po_number}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size={'small'}
                  label="หมายเลขคำสั่งซื้อฝ่ายลูกค้า (PO)"
                />
              )}
            />
          </div>
        )}{' '}
        <div className="w-full px-1 py-2">
          <Controller
            name="remark"
            control={control}
            defaultValue={manufacturingOrder?.remark || ''}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="หมายเหตุ"
                fullWidth
                size={'small'}
                rows={3}
                multiline={true}
                helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="w-full ">{renderOrderInfo()}</div>
    </div>
  );
};

ManufacturingOrderInfoForm.propTypes = {
  errors: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  manufacturingOrder: PropTypes.object,
  watch: PropTypes.func,
  paymentType: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  information: PropTypes.object,
};

ManufacturingOrderInfoForm.defaultProps = {
  order: null,
};

export default ManufacturingOrderInfoForm;
