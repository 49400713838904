/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from 'react';
import {
  Monitor as MonitorIcon,
  RefreshCw,
  Smartphone as PhoneIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  CardMOList,
  DaySpotAndRangePicker,
  DeleteManufacOrderModal,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  ManufacturingOrderExportExcelContainer,
  ManufacturingOrderTable,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { MANUFAC_ORDER_STATUS, MQ_TASK } from '@iarcpsu/emanufac-constant';
import {
  ManufacturingOrderColumn,
  ManufacturingOrderWithProcessColumn,
} from '@iarcpsu/emanufac-utils/columns';
import {
  Button,
  Card,
  CardContent,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import * as CONSTANT from '@/utils/constants';
import { useQuery } from '@/utils/functions';

/**
 * @function ManufactoringOrderByStatus
 * @memberof MMS/ManufacturingOrder
 * @description หน้าลิสต์รายการคำสั่งผลิต จะลิสต์มาที่ละ 10 อัน (Default) แต่ผู้ใช้สามารถเพิ่มได้
 * ใช้งานได้สองแบบจะมี Card Mode ที่ใช้ใทรศัพท์ กับ Table Mode ที่ใช้ใน Computer
 * Browser จะ Detect ให้เอง หน้านี้มีปัญหาด้านความช้าอยู่พอสมควร มีการใช้หลักการ Query Graphql
 * ไปที่ Server 2 รอบ รอบแรกเอาแค่ข้อมูล MO คร่าวๆ ส่วนครั้งที่2 ถึงไปใสั่งให้เอาข้อมูล Product
 * ต่างๆ มา อย่างร้อย User จะได้เห็นบางอย่างก่อน
 */
function ManufacturingOrderByStatus({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const information = useSelector((state) => state.information);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [selectManufacOrder, setSelectManufacOrder] = useState();
  const [deleteLoadingOpen, setIsDeleteLoadingOpen] = useState(false);
  const [deleteReference, setDeleteReference] = useState();
  const [fullDataMO, setFullDataMO] = useState(null);
  const [useRedis, setIsUseRedis] = useState(true);
  const [selectedView, setSelectedView] = useState(
    window.screen.width >= 768
      ? CONSTANT.DISPLAY_TYPE.TABLE.status_code
      : CONSTANT.DISPLAY_TYPE.CARD.status_code,
  );
  const browserQuery = useQuery();
  const selectedStatus = browserQuery.get('status');
  const [startDate, setStartDate] = useState(browserQuery.get('startDate'));
  const [endDate, setEndDate] = useState(browserQuery.get('endDate'));
  const [orderByField, setOrderByField] = useState('createdAt');
  const [orderBy, setOrderBy] = useState('desc');

  const findIsIncludeLog = () => {
    switch (selectedStatus) {
      case MANUFAC_ORDER_STATUS.SUCCESS.status_code:
        return true;
      case MANUFAC_ORDER_STATUS.CANCEL.status_code:
        return true;
      case MANUFAC_ORDER_STATUS.IN_PROGRESS.status_code:
        return false;
      case MANUFAC_ORDER_STATUS.LATED.status_code:
        return false;
      default:
        return true;
    }
  };

  const isIncludeLog = findIsIncludeLog();

  useEffect(() => {
    dispatch(actions.manufacturingOrderReset());

    return () => {};
  }, []);

  const query = gql`
    query FindManufacturingOrder($input: ManufacturingOrderInput) {
      findManufacturingOrders(input: $input) {
        currPage
        lastPage
        total
        rows {
          _id
          id
          assigner {
            firstname
            lastname
          }
          prefix
          running_number
          customer {
            type_code
            name
            address
            phone_number
            rating
          }
          process {
            product {
              type_code
              name
            }
            produce_material_instead
            produce_base_project_instead
            base_project {
              type_code
              name
              unit
            }
            product_as_material {
              type_code
              name
            }
            current {
              index
              step {
                name
              }
            }
            current_step_name
            customer {
              type_code
              name
            }
            other_customers {
              name
            }
            quantity
            price_per_unit
            additional_unit
            remark
          }
          discount
          completed
          deleted
          start_date
          expected_date
          remark
          status
          is_claimed
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          page,
          size,
          name,
          enableFetchInside: false,
          fetchCurrentStep: false,
          fetchCustomer: false,
          fetchMaterial: false,
          fetchProduct: browserQuery.get('productCode') !== '',
          stage: isIncludeLog ? undefined : 'NORMAL',
          fetchProcess: false,
          forceWithoutCache: !useRedis,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
          status: selectedStatus,
          includeLog: true,
          orderByField,
          orderBy,
          claimed: browserQuery.get('claimed') === 'true' ? true : undefined,
          productCode:
            browserQuery.get('productCode') === ''
              ? undefined
              : browserQuery.get('productCode'),
          assigner:
            browserQuery.get('assigner') === ''
              ? undefined
              : browserQuery.get('assigner'),
          assignerDepartment:
            browserQuery.get('assignerDepartment') === ''
              ? undefined
              : browserQuery.get('assignerDepartment'),
        },
      });

      const manufacturingOrderData = queryResult?.findManufacturingOrders;

      dispatch(actions.manufacturingOrderStateSet(manufacturingOrderData));
    } catch (err) {
      console.error('Mo,  Have an errors');
      dispatch(actions.manufacturingOrderReset());
    }
  };

  const callInsideFunction = async () => {
    try {
      console.log('Call Function Inside');
      const insideQuery = await graphqlClient.request(query, {
        input: {
          page,
          size,
          name,
          enableFetchInside: false,
          fetchCurrentStep: false,
          fetchCustomer: true,
          fetchMaterial: true,
          fetchProduct: true,
          fetchBaseProject: true,
          stage: isIncludeLog ? undefined : 'NORMAL',
          fetchProcess: true,
          forceWithoutCache: !useRedis,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
          status: selectedStatus,
          includeLog: true,
          orderByField,
          orderBy,
          claimed: browserQuery.get('claimed') === 'true' ? true : undefined,
          productCode:
            browserQuery.get('productCode') === ''
              ? undefined
              : browserQuery.get('productCode'),
          assigner:
            browserQuery.get('assigner') === ''
              ? undefined
              : browserQuery.get('assigner'),
          assignerDepartment:
            browserQuery.get('assignerDepartment') === ''
              ? undefined
              : browserQuery.get('assignerDepartment'),
        },
      });

      const insidedQueryResult = insideQuery?.findManufacturingOrders;
      setFullDataMO(insidedQueryResult);
    } catch (error) {
      console.error(error?.message);
      dispatch(actions.manufacMaterialError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    console.log('Waiting for time to fetch inside');
    const fetchInsideTimeout = setTimeout(() => {
      callInsideFunction();
    }, 1000);
    return () => {
      console.log('Clear Fetch inside Timeout');
      clearTimeout(fetchInsideTimeout);
    };
  }, [
    name,
    page,
    size,
    useRedis,
    startDate,
    endDate,
    isIncludeLog,
    orderByField,
    orderBy,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    setTotal(manufacturingOrder?.total);
    return () => {};
  }, [manufacturingOrder]);

  useEffect(() => {
    console.log('Full Data MOs is Toggle');
    if (fullDataMO && manufacturingOrder?.rows) {
      dispatch(actions.manufacturingOrderStateSet(fullDataMO));
    } else {
      console.log('แฮ่ แกเซ็ทไม่ได้หรอก');
    }
    return () => {};
  }, [fullDataMO]);

  const handleSocketIOFunction = {
    onSuccess: () => {
      setIsDeleteLoadingOpen(false);
      window.alert('ยกเลิกคำสั่งผลิตสั่งผลิตสำเร็จ');
      dispatch(actions.manufacturingOrderReset());
      queryDataFromServer();
      setDeleteReference();
    },
    onFail: (args) => {
      setIsDeleteLoadingOpen(false);
      console.log('Error', args?.error);
      window.alert(args?.message);
      dispatch(actions.manufacturingOrderReset());
      queryDataFromServer();
      setDeleteReference();
    },
  };

  const moQueryAPI = {
    page: 1,
    size: config.maxFetchSize,
    name: '',
    enableFetchInside: '',
    fetchCurrentStep: '',
    fetchCustomer: true,
    fetchMaterial: true,
    fetchProduct: true,
    fetchBaseProject: true,
    stage: isIncludeLog ? '' : 'NORMAL',
    fetchProcess: true,
    forceWithoutCache: true,
    startDate: dayjs(startDate).format('YYYY-MM-DD'),
    endDate: dayjs(endDate).format('YYYY-MM-DD'),
    status: selectedStatus || '',
    includeLog: true,
    orderByField,
    orderBy,
    claimed: browserQuery.get('claimed') || '',
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row justify-between">
        <div className="w-8/12 md:w-5/12">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="lg:w-3/12"></div>
        <div className="lg:w-2/12 px-1 md:w-2/12 justify-self-end">
          <ManufacturingOrderExportExcelContainer
            sheetName={`ManufacturingOrder-${selectedStatus || 'All'}-${dayjs(
              startDate,
            ).format('YYYY-MM-DD')}-${dayjs(endDate).format(
              'YYYY-MM-DD',
            )}-Export`}
            dataQuery={moQueryAPI}
            currentData={manufacturingOrder?.rows}
            dataAPIEndPoint="manufacturing-order"
            columnList={ManufacturingOrderColumn(information)}
            processColumnList={ManufacturingOrderWithProcessColumn(information)}
          />
        </div>
        <div className="w-1/12 px-1 flex justify-end">
          <ToggleButtonGroup
            value={selectedView}
            exclusive
            size="small"
            onChange={(event, value) => setSelectedView(value)}
          >
            <ToggleButton value={CONSTANT.DISPLAY_TYPE.TABLE.status_code}>
              <MonitorIcon size="16" />
            </ToggleButton>
            <ToggleButton value={CONSTANT.DISPLAY_TYPE.CARD.status_code}>
              <PhoneIcon size="16" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </Card>
  );

  const handleToInfoPage = (id) => {
    history.push(`manufacturing-orders/view/${id}`);
  };

  const handlePushToEditPage = (id) => {
    history.push(`manufacturing-orders/edit/${id}`);
  };

  const handleToDistributeMaterial = (id) => {
    history.push(`material-distribute/${id}`);
  };

  const handleModalDeleteOpen = (selectManufac) => {
    setSelectManufacOrder(selectManufac);
    setIsModalDeleteOpen(true);
  };

  const handleModalDeleteClose = () => {
    setIsModalDeleteOpen(false);
    setSelectManufacOrder();
  };

  const handleDelete = async (id) => {
    const tempManufacOrder = _.find(
      manufacturingOrder?.rows,
      (_manufacOrder) => _manufacOrder?._id === id,
    );

    handleModalDeleteOpen(tempManufacOrder);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleCreateProductionLine = (manufacOrderId, productId) => {
    history.push(`create-production-line/${manufacOrderId}/${productId}`);
  };

  const tableProps = {
    handleChangePage,
    handleChangeRowsPerPage,
    handleCreateProductionLine,
    handleDelete,
    handlePushToEditPage,
    handleToDistributeMaterial,
    handleToInfoPage,
    manufacturingOrder,
    page,
    size,
    total,
    orderByField,
    orderBy,
    setOrderByField,
    setOrderBy,
    displayCancleButton: false,
  };

  const renderTable = () => (
    <div>
      {selectedView === CONSTANT.DISPLAY_TYPE.TABLE.status_code ? (
        <ManufacturingOrderTable {...tableProps} />
      ) : (
        <CardMOList {...tableProps} />
      )}
    </div>
  );

  const renderDeleteModal = () => (
    <DeleteManufacOrderModal
      handleClose={handleModalDeleteClose}
      isOpen={isModalDeleteOpen}
      manufacturingOrder={selectManufacOrder}
      successHandle={(referenceNumber) => {
        handleModalDeleteClose();
        setIsDeleteLoadingOpen(true);
        setDeleteReference(referenceNumber);
      }}
    />
  );

  const renderActionButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-1">
      <Button
        variant="contained"
        color="teal"
        startIcon={<RefreshCw size={16} />}
        onClick={() => {
          setIsUseRedis(false);
        }}
      >
        รีเฟรช
      </Button>
    </div>
  );

  if (manufacturingOrder?.isLoading) {
    return <Loading />;
  }

  if (!manufacturingOrder?.isLoading && manufacturingOrder?.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderActionButton()}</div>
        </div>
        <LoadingDialogWithTimeout
          isLoading={deleteLoadingOpen}
          label="กำลังลบคำสั่งผลิต"
          action="REFRESH"
        />
        <SocketIOLoading
          taskCode={MQ_TASK.UPDATE_MANUFAC_ORDER.status_code}
          referenceNumber={deleteReference}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
        />
        {renderDeleteModal()}
        <div className="my-2">
          <BackButton />
        </div>

        <Card className="my-2">
          <CardContent>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/3">
                <div className="font-semibold">
                  {selectedStatus
                    ? `รายการคำสั่งผลิตสถานะ ${MANUFAC_ORDER_STATUS[selectedStatus]?.description}`
                    : 'รายการคำสั่งผลิตทุกสถานะ'}{' '}
                  {browserQuery.get('claimed') === 'true'
                    ? 'ที่เป็นงานเคลม'
                    : ''}
                </div>
                <div>
                  ตั้งแต่วันที่ {dayjs(startDate).format('D MMMM BBBB')}{' '}
                  ถึงวันที่ {dayjs(startDate).format('D MMMM BBBB')}{' '}
                </div>
                {browserQuery?.get('productCode') && (
                  <div>
                    รหัสสินค้า ประกอบด้วย {browserQuery?.get('productCode')}
                  </div>
                )}
              </div>
              <div className="w-full lg:w-2/3">
                <DaySpotAndRangePicker
                  enable
                  flex
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
                  endDate={endDate}
                  size="small"
                  defaultUseRange
                />
              </div>
            </div>
          </CardContent>
        </Card>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

ManufacturingOrderByStatus.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ManufacturingOrderByStatus.defaultProps = {
  title: '',
  subtitle: '',
};

export default ManufacturingOrderByStatus;
