/* eslint-disable simple-import-sort/imports */
import { combineReducers } from 'redux';

// Common
import UserReducers from './common/UserReducers';
import MeReducers from './common/MeReducers';
import InformationReducer from './common/InformationReducer';
import AvailableModuleReducer from './common/AvailableModuleReducer';

// CRM
import CustomerReducer from './features/CustomerReducer';
import CustomerTypeReducer from './features/CustomerTypeReducer';
import CustomerCommentReducer from './features/CustomerCommentReducer';
import CustomerRatingReducer from './features/CustomerRatingReducer';

import DepartmentReducer from './features/DepartmentReducer';
import RoleTypesReducer from './features/RoleTypesReducer';
import EmployeeReducer from './features/EmployeeReducer';
import NotifyReducer from './features/NotifyReducer';
import PayrollReducer from './features/PayrollReducer';
import PDFReportReducer from './features/PDFReportReducer';
import FeedbackReducer from './features/FeedbackReducer';
import PayrollAdjustmentReducer from './features/PayrollAdjustmentReducer';

// คลังวัตถุดิบ
import MaterialTypeReducer from './features/MaterialTypeReducer';
import MaterialReducer from './features/MaterialReducer';
import MaterialTransactionTypeReducer from './features/MaterialTransactionTypeReducer';
import PlaceReducer from './features/PlaceReducer';
import MaterialTransactionReducer from './features/MaterialTransactionReducer';
import MaterialStockLotReducer from './features/MaterialStockLotReducer';
import MaterialWithdrawReducer from './features/MaterialWithdrawReducer';
import WithdrawDestinationReducer from './features/WithdrawDestinationReducer';
import MaterialSourceReducer from './features/MaterialSourceReducer';

// คลังสินค้า
import WarehouseReducer from './features/WarehouseReducer';
import ProductTypeReducer from './features/ProductTypeReducer';
import ProductReducer from './features/ProductReducer';
import ProductTransactionTypeReducer from './features/ProductTransactionTypeReducer';
import ProductTransactionReducer from './features/ProductTransactionReducer';
import ProductStockLotReducer from './features/ProductStockLotReducer';
import WarehouseStockRequestReducer from './features/WarehouseStockRequestReducer';

// การผลิต
import ProcessTemplateReducer from './features/ProcessTemplateReducer';
import ProcessOfOrderReducer from './features/ProcessOfOrderReducer';
import StepReducer from './features/StepReducers';
import CommentReducer from './features/CommentReducer';
import ManufacturungTransactionReducer from './features/ManufacturingTransactionReducer';
import ManufacturingMaterialReducer from './features/ManufacturingMaterialReducer';
import MeasurementReducer from './features/MeasurementReducer';
import MeasurementTypeReducer from './features/MeasurementTypeReducer';
import MOTemplateReducer from './features/MOTemplateReducer';
import BOMTemplateReducer from './features/BOMTemplateReducer';
import BomCheckingReducer from './features/BomCheckingReducer';
import BaseProjectReducer from './features/BaseBrojectReducer';
import DashboardElementReducer from './features/DashboardElementReducer';
import MMSDashboardReducer from './features/MMSDashboardReducer';
import SummaryDashboardReducer from './features/SummaryDashboardReducer';
import MatterialSplittingProcessReducer from './features/MaterialSplittingProcessReducer';
import StepLogReducer from './features/StepLogReducer';
import ManufacturingOrderLogReducer from './features/ManufacturingOrderLogReducer';
import ProcessLogReducer from './features/ProcessLogReducer';

// Cost Estimation
import CostEstimationProfileReducer from './features/CostEstimationProfileReducer';
import PlaceLaborCostReducer from './features/PlaceLaborCostReducer';
import FactoryCapitalCostReducer from './features/FactoryCapitalCostReducer';

// คำขอวัตถุดิบ
import MaterialRequestReducer from './features/MaterialRequestReducer';
import PurchaseOrderReducer from './features/PurchaseOrderReducer';

// Order
import OrderReducer from './features/OrderReducer';
import PaymentTypeReducer from './features/PaymentTypeReducer';
import ShippingTypeReducers from './features/ShippingTypeReducers';
import ManufacturingOrderReducer from './features/ManufacturingOrderReducer';
import QuotationReducer from './features/QuotationReducer';
import QuotationLogReducer from './features/QuotationLogReducer';
import InternalDepositReducer from './features/InternalDepositReducer';
import QTAndIDTransactionReducer from './features/QTAndIDTransactionReducer';
import ShippingRoundReducer from './features/ShippingRoundReducer';

// Knowledge management
import UnitReducer from './features/Unit';
import OrganizationConstantReducer from './features/OrganizationConstant';
import ConversionReducer from './features/Conversion';
import ResultingFunctionTypeReducer from './features/ResultingFunctionTypeReducer';

// Machinery
import MachineReducer from './features/MachineReducer';

import MetalDividingReducer from '../../plugins/bj-metal-divide/redux/reducers/MetalDividingReducer';

const rootRuducer = combineReducers({
  me: MeReducers,
  availableModule: AvailableModuleReducer,
  baseProject: BaseProjectReducer,
  bjMetalDividing: MetalDividingReducer,
  bomChecking: BomCheckingReducer,
  bomTemplate: BOMTemplateReducer,
  comment: CommentReducer,
  conversion: ConversionReducer,
  costEstimationProfile: CostEstimationProfileReducer,
  customer: CustomerReducer,
  customerComment: CustomerCommentReducer,
  customerRating: CustomerRatingReducer,
  customerType: CustomerTypeReducer,
  dashboardElement: DashboardElementReducer,
  department: DepartmentReducer,
  employee: EmployeeReducer,
  factoryCapitalCost: FactoryCapitalCostReducer,
  feedback: FeedbackReducer,
  information: InformationReducer,
  internalDeposit: InternalDepositReducer,
  machine: MachineReducer,
  manufacturingMaterial: ManufacturingMaterialReducer,
  manufacturingOrder: ManufacturingOrderReducer,
  manufacturingOrderLog: ManufacturingOrderLogReducer,
  manufacturingTransaction: ManufacturungTransactionReducer,
  material: MaterialReducer,
  materialRequest: MaterialRequestReducer,
  materialSource: MaterialSourceReducer,
  materialSplitting: MatterialSplittingProcessReducer,
  materialStockLot: MaterialStockLotReducer,
  materialTransaction: MaterialTransactionReducer,
  materialTransactionType: MaterialTransactionTypeReducer,
  materialType: MaterialTypeReducer,
  materialWithdraw: MaterialWithdrawReducer,
  measurement: MeasurementReducer,
  measurementType: MeasurementTypeReducer,
  mmsDashboard: MMSDashboardReducer,
  moTemplate: MOTemplateReducer,
  notify: NotifyReducer,
  order: OrderReducer,
  organizationConstant: OrganizationConstantReducer,
  paymentType: PaymentTypeReducer,
  payroll: PayrollReducer,
  payrollAdjustment: PayrollAdjustmentReducer,
  pdfReport: PDFReportReducer,
  place: PlaceReducer,
  placeLaborCost: PlaceLaborCostReducer,
  process: ProcessOfOrderReducer,
  processLog: ProcessLogReducer,
  processTemplate: ProcessTemplateReducer,
  product: ProductReducer,
  productStockLot: ProductStockLotReducer,
  productTransaction: ProductTransactionReducer,
  productTransactionType: ProductTransactionTypeReducer,
  productType: ProductTypeReducer,
  purchaseOrder: PurchaseOrderReducer,
  qtAndIDTransaction: QTAndIDTransactionReducer,
  quotation: QuotationReducer,
  quotationLog: QuotationLogReducer,
  resultingFunctionType: ResultingFunctionTypeReducer,
  roletype: RoleTypesReducer,
  shippingType: ShippingTypeReducers,
  shippingRound: ShippingRoundReducer,
  step: StepReducer,
  stepLog: StepLogReducer,
  summaryDashboard: SummaryDashboardReducer,
  unit: UnitReducer,
  user: UserReducers,
  warehouse: WarehouseReducer,
  warehouseStockRequest: WarehouseStockRequestReducer,
  withdrawDestination: WithdrawDestinationReducer,
});

export default rootRuducer;
