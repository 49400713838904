import _ from 'lodash';

const getMaterialUsageList = ({
  productStockLot,
  parentWithChildLots,
  productStockLotIndex,
  previousLotList,
}) => {
  const materialUsageList = [];
  const lotWithNoPreviousLot = _.filter(previousLotList, (lot) =>
    _.isEmpty(lot?.previous_lot),
  );

  _.map(productStockLot?.previous_lot, (lot, index) => {
    const ancestorLot = _.filter(parentWithChildLots, (eachParentLot) => {
      const childLotId = _.map(eachParentLot?.child_lot, (each) =>
        each?._id?.toString(),
      );

      // console.log('Size of Child Lot', _.size(eachParentLot?.child_lot));
      return (
        _.includes(childLotId, lot?._id?.toString()) ||
        _.includes(childLotId, productStockLot?._id?.toString())
      );
    });

    _.map(ancestorLot, (each, ancestorIndex) => {
      const materialId = each?.material?._id;
      const materialName = each?.material?.name;
      const runningNumber = each?.running_number;

      const foundLotInfo = _.find(
        lotWithNoPreviousLot,
        (eachLot) => eachLot._id?.toString() === each._id?.toString(),
      );

      const lotPayload = {
        ref: `${productStockLotIndex}-${index}-${ancestorIndex}`,
        productStockLotId: productStockLot?._id,
        materialId,
        materialName,
        quantity: (lot?.amount || 0) / _.size(ancestorLot),
        purchaseDate: each?.purchase_date,
        reciptDate: each?.receipt_date,
        running_number: runningNumber,
        found_lot_running_number: foundLotInfo?.running_number,
        found_lot_quantity: foundLotInfo?.quantity,
        materialStockLotId: foundLotInfo?._id,
        cost:
          (foundLotInfo?.price / foundLotInfo?.quantity) *
          ((lot?.amount || 0) / _.size(ancestorLot)),
        unitCost: foundLotInfo?.price / foundLotInfo?.quantity,
        // otherParams: each,
      };
      materialUsageList.push(lotPayload);
    });
  });
  return materialUsageList;
};

export default getMaterialUsageList;
