import _ from 'lodash';
import filterOnlyChildMaterial from '../../../cost-estimation/filterOnlyChildMaterial';

const calTDABCArtifact = async ({
  previousLotList,
  currentLot,
  warehouseProfile,
  api,
  isFrontend,
  information,
}) => {
  console.log('Calculate TDABC Artifact');
  const place = warehouseProfile?.place;
  const allowMaterial = _.filter(
    previousLotList,
    (each) => each?.place?._id?.toString() === place?._id?.toString(),
  );

  const allowChildMaterial = filterOnlyChildMaterial({
    allowCategory: _.map(place?.allow_categories, (e) => e.toString()),
    currentLotMaterial: currentLot?.material,
    filterLotOnPlace: allowMaterial,
  });

  const hourPerDay = information?.setting?.pams?.daily_payroll?.working_hour;
  const minutePerDay = hourPerDay * 60;

  const calculateCostPerOneEmployee = (lot) => {
    const costPerMinute = (lot?.place?.average_labor_cost || 0) / minutePerDay;
    const minuteUse =
      (lot?.material?.average_unit_time || 0) * (lot?.quantity || 0);

    const costPerOneMan = costPerMinute * minuteUse;
    return costPerOneMan;
  };

  const calculateTotalCostPerMaterial = (lot) => {
    const costPerOneMan = calculateCostPerOneEmployee(lot);
    const costPerManyPeople =
      costPerOneMan * (lot?.material?.average_labor_amount || 1);
    return costPerManyPeople;
  };

  const newCostList = _.map(allowChildMaterial, (row) => ({
    ...row,
    artifact_cost: true,
    average_unit_time: row?.material?.average_unit_time,
    artifact_minute_use:
      (row?.material?.average_unit_time || 0) * (row?.quantity || 0),
    artifact_employee_name: `ตัวแทนพนักงานเฉลี่ย${row?.place?.name}`,
    artifact_employee_id: row?.place?._id,
    average_daily_labor_cost: row?.place?.average_labor_cost,
    average_labor_amount: row?.material?.average_labor_amount || 1,
    artifact_unit_cost: (row?.place?.average_labor_cost || 0) / minutePerDay,
    artifact_one_labor_cost: calculateCostPerOneEmployee(row),
    labor_cost: calculateTotalCostPerMaterial(row),
  }));

  const totalCost = _.sumBy(allowChildMaterial, (o) =>
    calculateTotalCostPerMaterial(o),
  );

  return {
    cost: totalCost,
    costList: newCostList,
  };
};

export default calTDABCArtifact;
