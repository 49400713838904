import React from 'react';
import { QUOTATION_STATUS } from '@iarcpsu/emanufac-constant';
import { Card } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter, findQuotationStatus } from '@/utils/functions';

import RatingTag from '../Box/CRM/RatingTag';

const CardQuotation = ({ quotation, setRatingDialogOpen }) => (
  <div>
    <Card className="p-4 ">
      <div className="flex flex-wrap divide-y">
        <div className="w-full py-4 text-lg font-semibold font-display ">
          รายละเอียด
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          วันที่เสนอราคา
        </div>
        <div className="w-1/2 py-4 ">
          {dayjs(quotation?.start_date).locale('th').format('DD MMM BBBB')}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          กำหนดยืนราคายืนราคา
        </div>
        <div className="w-1/2 py-4 ">
          {quotation?.valid_price_day} วัน ถึงวันที่{' '}
          {dayjs(quotation?.start_date)
            .add((quotation?.valid_price_day || 0) - 1, 'day')
            .locale('th')
            .format('DD MMM BBBB')}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          วันกำหนดส่งสินค้า
        </div>
        <div className="w-1/2 py-4 ">
          {dayjs(quotation?.expected_date).locale('th').format('DD MMM BBBB')}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          เลขที่ใบเสนอราคา
        </div>
        <div className="w-1/2 py-4 ">
          {quotation?.prefix}
          {quotation?.running_number}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">สถานะ</div>
        <div className="w-1/2 py-4 ">
          {QUOTATION_STATUS[findQuotationStatus(quotation)].description}
          {findQuotationStatus(quotation) === QUOTATION_STATUS.CANCLE && (
            <div>สาเหตุของการยกเลิก : {quotation?.delete_note}</div>
          )}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          ลูกค้า
        </div>
        <div className="w-1/2 py-4">
          <div className="text-sm">
            <div>
              <b>ชื่อ : </b> {quotation?.customer?.name || ' - '}
            </div>
            <div>
              <b>โทร : </b>
              {quotation?.customer?.phone_number || ' - '}
            </div>
            <div>
              <b>ที่อยู่ : </b>
              {quotation?.customer?.address || ' - '}
            </div>
            <div className="my-2">
              <RatingTag
                customer={quotation?.customer}
                allowUpdate
                asBox
                showEvenIsEmpty
                handleUpdateRating={() => {
                  console.log('Set Rating Dialog To True');
                  setRatingDialogOpen(true);
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          วิธีการการชำระเงิน
        </div>
        <div className="w-1/2 py-4 ">{quotation?.payment_method?.name}</div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          ลำดับความสำคัญของงาน
        </div>
        <div className="w-1/2 py-4 ">
          {quotation?.express ? 'ด่วน' : 'ปกติ'}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          พนักงานขาย / ผู้เสนอราคา
        </div>
        <div className="w-1/2 py-4 ">
          {quotation?.sales?.firstname} {quotation?.sales?.lastname}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          ผู้สร้างใบเสนอราคา
        </div>
        <div className="w-1/2 py-4 ">
          {quotation?.creator?.firstname} {quotation?.creator?.lastname}
        </div>{' '}
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          ใบเบิกใช้ภายใน
        </div>
        <div className="w-1/2 py-4 ">
          {!_.isEmpty(quotation?.internal_deposits) ? (
            <div className="text-xs bg-gray-200 rounded-sm p-1 my-1">
              มี {_.size(quotation?.internal_deposits)} รายการเบิกภายใน
            </div>
          ) : (
            'ไม่มีใบเบิกใช้ภายใน'
          )}
        </div>
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          หมายเหตุ
        </div>
        <div className="w-1/2 py-4 ">{quotation?.remark}</div>
      </div>
    </Card>
  </div>
);

export default CardQuotation;

CardQuotation.propTypes = {
  quotation: PropTypes.object,
  setRatingDialogOpen: PropTypes.func,
};
