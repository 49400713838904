import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  LoadingDialog,
  ProcessDeliveryForm,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  MANUFACTURING_TRANSACTION_TYPE,
  MQ_TASK,
} from '@iarcpsu/emanufac-constant';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { MMS } from '@/utils/functions';

/**
 * @function ProcessDelivery
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ProcessDelivery({ title, subtitle, onFirstDelivery }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const shippingType = useSelector((state) => state.shippingType);
  const shippingRound = useSelector((state) => state.shippingRound);
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const params = useParams();
  const [referenceNumber, setReferenceNumber] = useState();
  const [isDialogLoadingOn, setIsDialogLoadingOn] = useState(false);
  const [deliveryImage, setDeliveryImage] = useState([]);

  const {
    MaterialAnalyzer: { unNullManufacMaterialAnalyzedArrayAnalzer },
  } = MMS;

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.processGet(params.id));
      dispatch(
        actions.shippingTypeAll({
          page: 1,
          size: config.maxFetchSize,
        }),
      );
      dispatch(
        actions.shippingRoundAll({
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      dispatch(actions.processError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      delivery: {
        ...currentProcess.delivery,
        delivered: true,
      },
    },
  });

  const onSubmit = async (data) => {
    try {
      const payload = {
        delivery: {
          ...data.delivery,
          delivered: onFirstDelivery ? true : data?.delivery?.delivered,
          used_to_deliverd: true,
          image: _.isEmpty(deliveryImage)
            ? undefined
            : {
                image: deliveryImage[0]?.data_url,
                imageType: '',
                alt: '',
              },
        },
        referenceNumber,
      };
      const stepPayload = {
        process: currentProcess?._id,
        materials: _.map(
          unNullManufacMaterialAnalyzedArrayAnalzer(
            currentProcess?.manufacturing_materials,
          ),
          (_material) => ({
            material: _material?.materialInfo?._id,
            end: _material?.resultAmount,
            wastes: 0,
          }),
        ),
        employeeId: me?.userData?._id,
        manufacturing_transaction_type:
          MANUFACTURING_TRANSACTION_TYPE.WORK_CLOSE.status_code,
        referenceNumber,
      };

      await dispatch(actions.processPut(params.id, payload));
      setIsDialogLoadingOn(true);
      if (onFirstDelivery) {
        // Moving to next step
        dispatch(actions.stepPost(stepPayload));
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error?.message,
      });
    }
  };

  const handleSocketIOFunction = {
    onFail: (args) => {
      setIsDialogLoadingOn(false);
      Swal.fire({
        title: 'บันทึกมัดจำไม่สำเร็จ',
        icon: 'error',
        text: args.message,
      });
    },
    onSuccess: (args) => {
      setIsDialogLoadingOn(false);
      Swal.fire({
        icon: 'success',
        title: 'บันทึกข้อมูลการจัดส่งสำเร็จ',
      }).then(() => {
        history.goBack();
      });
    },
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.MOVING_STEP_UP.status_code}
          handleFail={handleSocketIOFunction.onFail}
          handleSuccess={handleSocketIOFunction.onSuccess}
        />
        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.UPDATE_PROCESS.status_code}
          handleFail={handleSocketIOFunction.onFail}
          handleSuccess={handleSocketIOFunction.onSuccess}
        />
        <LoadingDialog
          isLoading={isDialogLoadingOn}
          label="กำลังอัพเดทข้อมูลมัดจำ"
        />
        <div className="my-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardContent>
                <ProcessDeliveryForm
                  control={control}
                  defaultDelivery={currentProcess?.delivery}
                  shippingType={shippingType}
                  showDeliverySwitch={!onFirstDelivery}
                  image={deliveryImage}
                  setImage={setDeliveryImage}
                  shippingRound={shippingRound}
                />
              </CardContent>
            </Card>
            <div className="my-2 flex justify-end">
              <Button type="submit" variant="contained">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

ProcessDelivery.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onFirstDelivery: PropTypes.bool,
};

ProcessDelivery.defaultProps = {
  title: '',
  subtitle: '',
};
