import React from 'react';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import { QUOTATION_STATUS } from '@iarcpsu/emanufac-constant';
import { Button, Chip } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

const BoxQuotationByProductOrMaterial = ({
  quotationByProducts,
  typeKey,
  typeKeyTH,
  startDate,
  endDate,
  unsuccess = false,
}) => {
  const orderByQuantity = _.orderBy(quotationByProducts, ['total'], ['desc']);

  const mostQuantity = orderByQuantity[0];
  const anotherQuantity = _.tail(orderByQuantity);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="text-lg font-semibold mb-2">
          {typeKeyTH}ที่เสนอราคา {unsuccess ? 'ที่ถูกปฏิเสธ' : ''}
        </div>
        <Link
          to={`/spm/quotation/by-${typeKey}?startDate=${dayjs(startDate).format(
            'YYYY-MM-DD',
          )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}&status=${
            unsuccess ? QUOTATION_STATUS.CANCLE.status_code : ''
          }`}
        >
          <Button endIcon={<ExternalLink size={16} />}>
            {typeKeyTH}ทั้งหมด
          </Button>
        </Link>
      </div>
      {mostQuantity && (
        <div className="w-full">
          <Link
            className="text-xl flex items-center gap-2"
            to={`/spm/quotation/by-${typeKey}/detail/${
              mostQuantity?.[typeKey]?._id
            }?startDate=${dayjs(startDate).format(
              'YYYY-MM-DD',
            )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}&status=${
              unsuccess ? QUOTATION_STATUS.CANCLE.status_code : ''
            }`}
          >
            <Chip label="#1" size="small" className="mr-1" />{' '}
            {mostQuantity?.[typeKey]?.name}
            <ExternalLink size={14} />
          </Link>
          <div className="flex justify-end">
            {mostQuantity?.additional_unit ? (
              <div>
                {mostQuantity?.total} {mostQuantity?.additional_unit}
              </div>
            ) : (
              <MultiUnitProdOrMatBox
                foundMaterial={mostQuantity?.[typeKey]}
                quantity={mostQuantity?.total}
                noWrap
              />
            )}
          </div>
        </div>
      )}
      {mostQuantity && <hr className="my-2" />}
      {_.map(anotherQuantity, (eachProduct) => (
        <div className="flex justify-between">
          <Link
            className="flex gap-2 items-center"
            to={`/spm/quotation/by-${typeKey}/detail/${
              eachProduct?.[typeKey]?._id
            }?startDate=${dayjs(startDate).format(
              'YYYY-MM-DD',
            )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}&status=${
              unsuccess ? QUOTATION_STATUS.CANCLE.status_code : ''
            }`}
          >
            <div>{eachProduct?.[typeKey]?.name}</div>
            <ExternalLink size={12} />
          </Link>
          {eachProduct?.additional_unit ? (
            <div>
              {eachProduct?.total} {eachProduct?.additional_unit}
            </div>
          ) : (
            <MultiUnitProdOrMatBox
              foundMaterial={eachProduct?.[typeKey]}
              quantity={eachProduct?.total}
              noWrap
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default BoxQuotationByProductOrMaterial;

BoxQuotationByProductOrMaterial.propTypes = {
  quotationByProducts: PropTypes.arrayOf(PropTypes.object),
  typeKey: PropTypes.string,
  typeKeyTH: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};
