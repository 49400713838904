import {
  SHIPPING_ROUND_ALL,
  SHIPPING_ROUND_DELETE,
  SHIPPING_ROUND_ERROR,
  SHIPPING_ROUND_GET,
  SHIPPING_ROUND_LOADING,
  SHIPPING_ROUND_POST,
  SHIPPING_ROUND_PUT,
} from '../../actions/types';
import { BaseReducer } from '../../class';

const basedReducerObject = new BaseReducer({
  allConst: SHIPPING_ROUND_ALL,
  getConst: SHIPPING_ROUND_GET,
  postConst: SHIPPING_ROUND_POST,
  putConst: SHIPPING_ROUND_PUT,
  deleteConst: SHIPPING_ROUND_DELETE,
  errorConst: SHIPPING_ROUND_ERROR,
  loadingConst: SHIPPING_ROUND_LOADING,
});

const initialState = {
  isLoading: true,
  isCompleted: true,
};

const ShippingRoundReducer = (state = initialState, action) =>
  basedReducerObject.getReducer(state, action);

export default ShippingRoundReducer;
