/* eslint-disable no-nested-ternary */
import React from 'react';
import { ChevronLeft, ChevronRight, Trash as DeleteIcon } from 'react-feather';
import { Controller, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ProcessDeliveryForm from './ProcessDeliveryForm';

const EmployeeAssignmentForm = ({
  control,
  employee,
  department,
  steps,
  measurementType,
  setValue,
  watch,
  resultingFunctionTypes,
  shippingRound,
  shippingType,
}) => {
  const { fields, append, move, remove, insert } = useFieldArray({
    control,
    name: 'steps',
  });

  const information = useSelector((state) => state.information);
  const {
    setting: { mms: mmsSetting },
  } = information;

  const moveField = (anotherField, direction, index) => {
    if (
      anotherField?.started ||
      anotherField?.deleted ||
      anotherField?.completed
    ) {
      return index;
    }

    if (direction === 1) {
      if (
        mmsSetting?.fixedLastStep?.enable === true &&
        anotherField?.index + 1 === _.size(steps)
      ) {
        return index;
      }
      return index + 1;
    }

    return index - 1;
  };

  // Append step if fix last step or not
  const appendStep = () => {
    if (mmsSetting?.fixedLastStep?.enable === true) {
      insert(_.size(fields) - 2, {});
    } else {
      append({});
    }
  };

  // eslint-disable-next-line arrow-body-style
  const EachStepForm = ({ _field, index }) => {
    const {
      fields: measurementFields,
      append: measurementAppend,
      remove: measurementRemove,
    } = useFieldArray({
      control,
      name: `steps[${index}].measurements`,
    });

    const {
      fields: resultFnFields,
      append: resultFnAppend,
      remove: resultFnRemove,
    } = useFieldArray({
      control,
      name: `steps[${index}].resultingFunctionTypes`,
    });

    return (
      <div className="p-2" key={_field?.id}>
        <div className="border rounded-md p-2">
          <div className="flex justify-between">
            <div className="p-2 font-display">
              {/* <h5 className="font-semibold">{_field?.name} </h5>
            <h6 className="text-sm">
              {_field?.responsible?.department?.name ? 'แผนก : ' : ''}{' '}
              {_field?.responsible?.department?.name}
            </h6> */}
              ขั้นที่ {index + 1}
            </div>
            <div className="flex h-full gap-2">
              <IconButton
                size="small"
                onClick={() =>
                  move(
                    index,
                    index > 0 ? moveField(fields[index - 1], -1, index) : 0,
                  )
                }
                disabled={
                  _field?.completed ||
                  _field?.deleted ||
                  _field?.started ||
                  (mmsSetting?.fixedLastStep?.enable &&
                    _field?.index + 1 === _.size(steps))
                }
              >
                <ChevronLeft />
              </IconButton>{' '}
              <IconButton
                color="error"
                size="small"
                disabled={
                  _field?.completed || _field?.deleted || _field?.started
                }
                onClick={() => remove(index)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                size="small"
                disabled={
                  _field?.completed || _field?.deleted || _field?.started
                }
                onClick={() =>
                  move(
                    index,
                    index < _.size(fields) - 1
                      ? moveField(fields[index + 1], 1, index)
                      : index,
                  )
                }
              >
                <ChevronRight />
              </IconButton>{' '}
            </div>
          </div>

          <div className="my-4">
            <Controller
              control={control}
              name={`steps[${index}].name`}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="ชื่องาน"
                  fullWidth
                  disabled={
                    mmsSetting?.fixedLastStep?.enable &&
                    _field?.index + 1 === _.size(steps)
                  }
                />
              )}
            />
          </div>
          <div className="my-4">
            {!_.isEmpty(department?.rows) && (
              <Controller
                control={control}
                name={`steps[${index}].responsible.department`}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={department?.rows}
                    placeholder="เลือกแผนก"
                    size="small"
                    disabled={_field?.completed || _field?.deleted}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name} `
                }
                    renderInput={(params) => (
                      <TextField label="เลือกแผนก" {...params} />
                    )}
                    renderOption={(props, option) => (
                      <div {...props}>{option.name}</div>
                    )}
                  />
                )}
              />
            )}
          </div>

          <div className="my-4">
            {!_.isEmpty(employee?.rows) && (
              <Controller
                control={control}
                name={`steps[${index}].responsible.employee`}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={employee?.rows}
                    placeholder="เลือกพนักงาน"
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    disabled={_field?.completed || _field?.deleted}
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`
                }
                    renderInput={(params) => (
                      <TextField label="เลือกพนักงาน" {...params} />
                    )}
                    renderOption={(props, option) => (
                      <div className="flex" {...props}>
                        <div className="w-1/6">
                          <Avatar src={option?.image?.url}>
                            {option?.firstname} {option?.lastname}
                          </Avatar>
                        </div>
                        <div className="w-5/6">
                          {option.firstname} {option.lastname}
                        </div>
                      </div>
                    )}
                  />
                )}
              />
            )}
          </div>

          <div className="my-4">
            <Controller
              control={control}
              name={`steps[${index}].material_associate`}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox {...field} defaultChecked={field?.value} />
                  }
                  label="เกี่ยวข้องกับการใช้วัตถุดิบ"
                />
              )}
            />
          </div>
          {watch(`steps[${index}].is_delivery_step`) && (
            <div className="my-4">
              <div className="bg-yellow-300 p-1 rounded-md px-2">
                ขั้นตอนการจัดส่ง
              </div>
              <div className="border p-4 my-2 rounded-md">
                <ProcessDeliveryForm
                  control={control}
                  showImageUpload={false}
                  showDeliverySwitch={false}
                  shippingRound={shippingRound}
                  shippingType={shippingType}
                />
              </div>
            </div>
          )}

          {/** ********** MEASUREMENT VARIABLES ********* */}
          <div className="my-4 flex gap-2 justify-between">
            <div>ตัวแปรที่จะเก็บค่าเพิ่มเติม</div>
            <Button
              size="small"
              variant="contained"
              color="inherit"
              onClick={() => measurementAppend({})}
            >
              เพิ่ม
            </Button>
          </div>
          {!_.isEmpty(measurementType?.rows) &&
            _.map(measurementFields, (_measurementField, measurementIndex) => (
              <div key={_measurementField?.id}>
                +
                <div className="my-2 flex gap-2">
                  <div className="w-10/12">
                    {watch(
                      `steps[${index}].measurement[${measurementIndex}].mark_delete`,
                    ) !== true ? (
                      <Controller
                        control={control}
                        name={`steps[${index}].measurement[${measurementIndex}].measurement_type`}
                        defaultValue={_measurementField?.measurement_type}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            options={measurementType?.rows}
                            placeholder="เลือกตัวแปร"
                            size="small"
                            onChange={(e, newValue) => {
                              field.onChange(newValue);
                            }}
                            // prettier-ignore
                            getOptionLabel={(option) => `${option?.name}`}
                            renderInput={(params) => (
                              <TextField label="เลือกตัวแปร" {...params} />
                            )}
                            renderOption={(props, option) => (
                              <div {...props}>{option.name}</div>
                            )}
                          />
                        )}
                      />
                    ) : (
                      <div className="line-through text-gray-500">
                        {watch(
                          `steps[${index}].measurement[${measurementIndex}].measurement_type.name`,
                        )}
                      </div>
                    )}

                    <Controller
                      control={control}
                      name={`steps[${index}].measurement[${measurementIndex}]._id`}
                      defaultValue={_measurementField?._id}
                      render={({ field }) => <input {...field} hidden />}
                    />
                  </div>
                  <div className="w-1/12 self-center">
                    {watch(
                      `steps[${index}].measurement[${measurementIndex}].mark_delete`,
                    ) !== true ? (
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        className="px-2 "
                        onClick={() => {
                          if (
                            watch(
                              `steps[${index}].measurement[${measurementIndex}]._id`,
                            )
                          ) {
                            setValue(
                              `steps[${index}].measurement[${measurementIndex}].mark_delete`,
                              true,
                            );
                          } else {
                            measurementRemove(measurementIndex);
                          }
                        }}
                      >
                        ลบ
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        variant="contained"
                        color="teal"
                        className="px-2 "
                        onClick={() =>
                          setValue(
                            `steps[${index}].measurement[${measurementIndex}].mark_delete`,
                            false,
                          )
                        }
                      >
                        กลับมา
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}

          {/** ********** RESULTING FUNCTION ********* */}
          <div className="my-4 flex gap-2 justify-between">
            <div>การแสดงค่าเพิ่มเติม</div>
            <Button
              size="small"
              variant="contained"
              color="inherit"
              onClick={() => resultFnAppend({})}
            >
              เพิ่ม
            </Button>
          </div>
          {!_.isEmpty(resultingFunctionTypes?.rows) &&
            _.map(resultFnFields, (_resultFnField, resultingFnIndex) => (
              <div key={_resultFnField?.id}>
                <div className="my-2 flex gap-2">
                  <div className="w-10/12">
                    <Controller
                      control={control}
                      name={`steps[${index}].resultingFunctionTypes[${resultingFnIndex}]`}
                      defaultValue={_resultFnField}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          options={resultingFunctionTypes?.rows}
                          placeholder="เลือกการแสดงผล"
                          size="small"
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                          }}
                          // prettier-ignore
                          getOptionLabel={(option) => `${option?.name}`}
                          renderInput={(params) => (
                            <TextField label="เลือกการแสดงผล" {...params} />
                          )}
                          renderOption={(props, option) => (
                            <div {...props}>{option.name}</div>
                          )}
                        />
                      )}
                    />
                  </div>
                  <div className="w-1/12 self-center">
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      className="px-2 "
                      onClick={() => {
                        resultFnRemove(resultingFnIndex);
                      }}
                    >
                      ลบ
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  EachStepForm.propTypes = {
    _field: PropTypes.object,
    index: PropTypes.number,
  };

  return (
    <div>
      <div>
        <div className="flex justify-end">
          <Button variant="contained" onClick={() => appendStep()}>
            เพิ่มขั้นตอน
          </Button>
        </div>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 my-2 overflow-y-auto max-h-96 md:max-h-full ">
        {_.map(fields, (_field, index) => (
          <EachStepForm _field={_field} index={index} key={_field.id} />
        ))}
      </div>
    </div>
  );
};

EmployeeAssignmentForm.propTypes = {
  control: PropTypes.func,
  employee: PropTypes.arrayOf(PropTypes.object),
  department: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  steps: PropTypes.arrayOf(PropTypes.object),
  measurementType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  resultingFunctionTypes: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setValue: PropTypes.func,
  watch: PropTypes.func,
  shippingRound: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  shippingType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default EmployeeAssignmentForm;
