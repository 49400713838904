import dayjs from 'dayjs';
import _ from 'lodash';
import hash from 'object-hash';

import * as actions from '../../../redux/actions';
import * as CONSTANT from '../../constants';

import { unNullManufacMaterialAnalyzedArrayAnalzer } from './materialAnalyzer';

/**
 * @function handleStartProcess
 * @description เริ่มงานและมอบหมายให้แผนกแรก
 * @typedef {object} HandleStartParams
 * @property {function} dispatch Dispatch เอาค่าที่ได้จาก dispatch = useDispatch() มา
 * @property {string} employeeId ไอดีของพนักงาน หาได้จาก me?.userData?._id
 * @property {object} currentProcess process ปัจจุบันที่ทำงานอยู่
 * @property {function} handleSuccess ฟังก์ชันที่จำให้มันทำต่อหลังจากทำตัวนี้สำเร็จ
 * @param { HandleStartParams } {}
 */

const handleStartProcess = async ({
  dispatch,
  currentProcess,
  employeeId,
  handleSuccess = () => {},
  timestamp,
}) => {
  if (
    !_.isEmpty(currentProcess?.product?.steps) ||
    !_.isEmpty(currentProcess?.steps)
  ) {
    try {
      await dispatch(
        actions.stepPost({
          process: currentProcess?._id,
          materials: _.map(currentProcess?.materials, (_material) => ({
            material: _material?.material,
            begin: _material?.begin,
            end: _material?.begin, // ตอนแรกค่าปริมาณเริ่มแรก ผลผลิต = สารตั้งต้น แล้วค่อยไปลดเอา
            wastes: 0,
          })),
          employeeId,
          manufacturing_transaction_type:
            CONSTANT.MANUFACTURING_TRANSACTION_TYPE.PROCESS_START.status_code,
          timestamp,
        }),
      );

      await dispatch(actions.processGet(currentProcess?._id));
      await dispatch(
        actions.manufacTxnAll({
          processId: currentProcess?._id,
          size: 200,
          page: 1,
        }),
      );
      window.alert('เริ่มการผลิตได้สำเร็จ');
      handleSuccess();
    } catch (err) {
      window.alert(`ไม่สามารถเริ่มงานในขั้นตอนถัดไปได้่ ${err?.message}`);
      console.error('Create Step Error', err);
    }
  } else {
    window.alert(
      'คุณยังไม่ได้สร้างวิธีการผลิตสำหรับสินค้าชิ้นนี้ กรุณาไปที่ การตั้งค่า และ รูปแบบการผลิต เพื่อสร้างรูปแบบการผลิต',
    );
  }
};

/**
 * @function handleNextStep
 * @description เริ่มงานและมอบหมายให้แผนกแรก
 * @typedef {object} HandleNextStepParam
 * @property {function} dispatch Dispatch เอาค่าที่ได้จาก dispatch = useDispatch() มา
 * @property {object} currentStep งานปัจจุบัน
 * @property {object} currentProcess โพรเซสงานปัจจุบัน
 * @property {string} employeeId ไอดีของพนักงาน หาได้จาก me?.userData?._id
 * @property {function} handleSuccess ฟังก์ชันที่จำให้มันทำต่อหลังจากทำตัวนี้สำเร็จ
 * @param {HandleNextStepParam} {}
 */

const handleNextStep = async ({
  dispatch,
  currentProcess,
  employeeId,
  manufacMaterials,
  handleSuccess = () => {},
  fetchManufacTxn = true,
}) => {
  console.log('manufacMaterials', manufacMaterials);
  const payload = {
    process: currentProcess?._id,
    materials: _.map(
      unNullManufacMaterialAnalyzedArrayAnalzer(manufacMaterials),
      (_material) => ({
        material: _material?.materialInfo?._id,
        end: _material?.resultAmount,
        wastes: 0,
      }),
    ),
    employeeId,
    manufacturing_transaction_type:
      CONSTANT.MANUFACTURING_TRANSACTION_TYPE.WORK_CLOSE.status_code,
  };
  console.log('Next Step Payload', payload);
  try {
    await dispatch(actions.stepPost(payload));
    await dispatch(actions.processGet(currentProcess?._id));

    if (fetchManufacTxn) {
      console.info('fetchManufacTxn');
      await dispatch(
        actions.manufacTxnAll({
          processId: currentProcess?._id,
          size: 200,
          page: 1,
        }),
      );
    }

    handleSuccess();
  } catch (err) {
    window.alert(`ไม่สามารถไปสู่ขั้นตอนถัดไปได้ ${err?.message}`);
    console.log(err.message);
    console.error('Create Step Error', err);
  }
};

/**
 * @function handleCloseWork
 * @description ปิดงาน ทำการ complete step สุดท้าย จากนั้นระบบจะ check process เป็นเสร็จ
 * @param {function} dispatch Dispatch เอาค่าที่ได้จาก dispatch = useDispatch() มา
 * @param {object} currentProcess โพรเสสงานปัจจุบัน
 * @param {string} employeeId ไอดีของพนักงาน หาได้จาก me?.userData?._id
 * @param {function} handleSuccess ฟังก์ชันที่จำให้มันทำต่อหลังจากทำตัวนี้สำเร็จ
 */

const handleCloseWork = async (
  dispatch,
  currentProcess,
  employeeId,
  handleSuccess = () => {},
) => {
  try {
    await dispatch(
      actions.stepPost({
        process: currentProcess?._id,
        employeeId,
        manufacturing_transaction_type:
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.WORK_CLOSE.status_code,
      }),
    );
    handleSuccess();
    // TODO: Remove
    await dispatch(actions.processGet(currentProcess?._id));
    await dispatch(
      actions.manufacTxnAll({
        processId: currentProcess?._id,
        size: 200,
        page: 1,
      }),
    );
  } catch (err) {
    window.alert(`ปิดงานไม่สำเร็จ ${err?.message}`);
    console.error('Close Step Error', err);
  }
};

/**
 * @function handleToPurchase
 * @description ทำสถานะสินค้าที่ได้จากการผลิตเป็นสินค้าพร้อมขาย
 * @param {function} dispatch Dispatch เอาค่าที่ได้จาก dispatch = useDispatch() มา
 * @param {object} currentProcess โพรเสสงานปัจจุบัน
 * @param {string} employeeId ไอดีของพนักงาน หาได้จาก me?.userData?._id
 * @param {function} handleSuccess ฟังก์ชันที่จำให้มันทำต่อหลังจากทำตัวนี้สำเร็จ
 */

const handleToPurchase = async (
  dispatch,
  currentProcess,
  employeeId,
  handleSuccess = () => {},
) => {
  try {
    await dispatch(
      actions.processPut(currentProcess?._id, {
        completed: true,
        employeeId,
        manufacturing_transaction_type:
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.PROCESS_CLOSE.status_code,
      }),
    );

    window.alert('การผลิตเสร็จสิ้นแล้ว');
    await dispatch(actions.processGet(currentProcess?._id));
    handleSuccess();
  } catch (error) {
    window.alert(
      `ไม่สามารถเปลี่ยนสถานะสินค้าเป็นพร้อมจำหน่ายได้ ${error?.message}`,
    );
    console.log('Process change to complete and handle purchase error', error);
  }
};

/**
 *
 * @function handleDirectRequestMaterial
 * @description เบิกวัตถุดิบออกจากคลังวัตถุดิบโดยตรง ไม่ผ่านการอนุมัติ
 * @typedef {object} HandleRequestMaterialParams
 * @property {function} dispatch  Dispatch เอาค่าที่ได้จาก dispatch = useDispatch() มา
 * @property {object} data ค่าที่ได้มาจาก React Hook Form
 * @property {object} material  state ของ material
 * @property {object} currentProcess  Process ปัจจุบัน
 * @property {object} step Step ปัจจุบัน
 * @property {object} me me จาก State ของ me
 * @property {boolean} requestFromProcess มาจาก Process
 * ใช่หรือไม่ ถ้าใช่เป็น true ถ้าไม่ใช่ มันมาจาก Step เป็น False
 * @property {function} closeHandle ฟังก์ชันที่ใช้ปิด Modal
 * @property {boolean} addToSplittingMaterial ใส่เข้าไปในกระบวนการแบบแยกวัตถุดิบหรือไม่
 * @property {string} materialSplittingProcessId ID ของ กระบวนการแยกวัตถุดิบที่ต้องการ
 * @param {HandleRequestMaterialParams} {}
 * @returns {Promise} Promise object function
 */

const handleDirectRequestMaterial = async ({
  dispatch,
  data,
  currentProcess,
  step,
  me,
  closeHandle = () => {},
  referenceNumber,
  addToSplittingMaterial = false,
  materialSplittingProcessId = null,
}) => {
  try {
    // หาลำดับ Process ปัจจุบัน ถ้าไม่มีให้ไปหาที่ step แรก เพื่อที่เราจะส่งวัตถุดิบไปถูกที่
    const currentStep =
      currentProcess?.current?.index >= 0 ? currentProcess?.current?.index : 0;

    // กระบวนการ / step ที่จะได้รับวัตถุดิบ
    const recievedStep = _.find(
      currentProcess?.product?.steps,
      (eachStep) => eachStep?.index === currentStep,
    );

    // เช็คอีกรอบว่าเลือก Transaction Type แล้วยัง
    if (!data?.material_transaction_type) {
      window.alert('กรุณาเลือกประเภทการขอเบิกวัตถุดิบ');
      return;
    }

    dispatch(
      actions.processMaterialWithdrawStockLot({
        data,
        currentProcess,
        step,
        me,
        recievedStep,
        referenceNumber,
        addToSplittingMaterial,
        materialSplittingProcessId,
      }),
    );
  } catch (err) {
    console.error('Error on Withdraw Material', err);
    window.alert(`สร้างการเบิกวัตถุดิบไม่สำเร็จ ${err?.message}`);
  }
  closeHandle();
};

/**  *
 * @function handleFIFORequestMaterial
 * @description เบิกวัตถุดิบออกจากคลังวัตถุดิบโดยตรง ไม่ผ่านการอนุมัติ แบบ FIFO โดยจัดการที่ backend
 * @typedef {object} HandleFIFORequestParam
 * @property {function} dispatch  Dispatch เอาค่าที่ได้จาก dispatch = useDispatch() มา
 * @property {object} data ค่าที่ได้มาจาก React Hook Form
 * @property {object} currentProcess  Process ปัจจุบัน
 * @property {object} step Step ปัจจุบัน
 * @property {object} me me จาก State ของ me
 * @property {function} closeHandle ฟังก์ชันที่ใช้ปิด Modal
 * @property {function} successHandle ฟังก์ชันที่จะให้ทำต่อเมื่อเสร็จฟังก์ชันนี้แล้ว
 * @property {boolean} addToSplittingMaterial ใส่เข้าไปในกระบวนการแบบแยกวัตถุดิบหรือไม่
 * @property {string} materialSplittingProcessId ID ของ กระบวนการแยกวัตถุดิบที่ต้องการ
 * @param {HandleFIFORequestParam} {}
 * @returns {Promise} Promise object function
 */
const handleFIFORequestMaterial = async ({
  dispatch,
  data,
  currentProcess,
  step,
  me,
  closeHandle = () => {},
  referenceNumber,
  addToSplittingMaterial = false,
  materialSplittingProcessId = null,
}) => {
  try {
    // หาลำดับ Process ปัจจุบัน ถ้าไม่มีให้ไปหาที่ step แรก เพื่อที่เราจะส่งวัตถุดิบไปถูกที่
    const currentStep =
      currentProcess?.current?.index >= 0 ? currentProcess?.current?.index : 0;

    // กระบวนการ / step ที่จะได้รับวัตถุดิบ
    const recievedStep = _.find(
      currentProcess?.product?.steps,
      (eachStep) => eachStep?.index === currentStep,
    );

    // เช็คอีกรอบว่าเลือก Transaction Type แล้วยัง
    if (!data?.material_transaction_type) {
      window.alert('กรุณาเลือกประเภทการขอเบิกวัตถุดิบ');
      return;
    }

    dispatch(
      actions.processMaterialWithdrawFIFO({
        data,
        currentProcess,
        step,
        employee: me,
        recievedStep,
        referenceNumber,
        addToSplittingMaterial,
        materialSplittingProcessId,
      }),
    );
  } catch (err) {
    console.error('Error on Withdraw Material', err);
    window.alert(`สร้างการเบิกวัตถุดิบไม่สำเร็จ ${err?.message}`);
  }
  closeHandle();
};
/**
 * @function handleCreateRequestOfMaterial
 * @description สร้างคำขอเบิกวัตถุดิบไปที่คลังวัตถุดิบ แบบที่ต้องมีคน Approve
 * @param {*} dispatch dispatch จาก dispatch= useDispatch()
 * @param {*} data data จาก react-hook-form
 * @param {*} currentProcess process ปัจจุบัน
 * @param {*} me me จาก state
 * @param {*} successHandle ฟังก์ชันที่จะให้ทำหลังจากจบฟังก์ขั่นนี้
 *
 */
const handleCreateRequestOfMaterial = async (
  dispatch,
  data,
  currentProcess,
  me,
  successHandle = () => {},
  referenceNumber,
) => {
  try {
    const recievedStep = _.find(
      currentProcess?.product?.steps,
      (eachStep) => eachStep?.index === 0,
    );

    dispatch(
      actions.processMaterialRequest({
        data,
        currentProcess,
        step: recievedStep,
        me,
        recievedStep,
        referenceNumber,
      }),
    );
    successHandle();
  } catch (err) {
    console.error('Error on Withdraw Material', err);
    window.alert(`ส่งคำขอเบิกวัตถุดิบไม่สำเร็จ ${err?.message}`);
  }
};

const handleCancleProcess = async (
  dispatch,
  currentProcess,
  data,
  employeeId,
  successHandle,
) => {
  try {
    const confirm = window.confirm('ยืนยันการยกเลิกไลน์การผลิต');
    const { delete_note = '' } = data;
    if (confirm) {
      await dispatch(
        actions.processPut(currentProcess?._id, {
          deleted: true,
          employeeId,
          delete_note,
          manufacturing_transaction_type:
            CONSTANT.MANUFACTURING_TRANSACTION_TYPE.PROCESS_CANCLE.status_code,
        }),
      );

      await dispatch(
        actions.manufacturingOrderGet(
          currentProcess?.manufacturing_order?._id ||
            currentProcess?.manufacturing_order,
        ),
      );

      successHandle();
    }
  } catch (error) {
    console.error('Error on Cancle Process', error);
    window.alert(`ยกเลิกไลน์การผลิตไม่สำเร็จ ${error?.message}`);
  }
};

const handleProcessWorkAssignment = async (
  dispatch,
  processId,
  assignmentSteps,
  employeeId,
) => {
  try {
    const flattenAssignmentArray = _.map(
      assignmentSteps?.steps,
      (_step, index) => ({
        ..._step,
        index,
        responsible: {
          employee: _step?.responsible?.employee?._id,
          department: _step?.responsible?.department?._id,
        },
      }),
    );
    console.log('Assignment Array', flattenAssignmentArray);
    await dispatch(
      actions.processPut(processId, {
        assignments: flattenAssignmentArray,
        employeeId,
        manufacturing_transaction_type:
          CONSTANT.MANUFACTURING_TRANSACTION_TYPE.PROCESS_UPDATE.status_code,
        delivery: assignmentSteps?.delivery,
      }),
    );
    window.alert('มอบหมายงานสำเร็จ');
  } catch (error) {
    window.alert(`ไม่สามารถมอบหมายงานได้ ${error?.message}`);
    console.error('Handle Process Work Assignement Error ', error);
  }
};

const handleAddAsMaterial = async ({ dispatch, processId, data, me }) => {
  try {
    // console.log('Data', data);
    const materialStockLotPayload = {
      material: _.map(data.material, (eachMaterial) => ({
        ...eachMaterial,
        material: eachMaterial?.material?._id,
        lot_number: eachMaterial?.lot_number,
        quantity: eachMaterial.quantity,
        amount: eachMaterial.quantity,
      })),
      material_transaction_type: data.material_transaction_type,
      place: data.place,
      status: CONSTANT.MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
      source: 'จากการผลิต',
      purchase_date: dayjs().toDate(),
      receipt_date: dayjs().toDate(),
      issue_date: dayjs().toDate(),
      recipient_name: `${me?.userData?.firstname} ${me?.userData?.lastname}`,
      process: processId,
      order_department: me?.userData?.department?._id,
    };
    //  console.log('Material Stock lot payload', materialStockLotPayload);
    await dispatch(actions.materialStockLotCreate(materialStockLotPayload));
    // window.alert('เพิ่มผลผลิตลงในคลังวัตถุดิบเรียบร้อยแล้ว');
  } catch (error) {
    window.alert(
      `ไม่สามารถเพิ่มเป็นวัตถุดิบไปยังคลังวัตถุดิบได้ ${error?.message}`,
    );
    console.error('Handle Add As Material Error ', error);
  }
};

export default {
  handleStartProcess,
  handleCloseWork,
  handleNextStep,
  handleToPurchase,
  handleDirectRequestMaterial,
  handleCreateRequestOfMaterial,
  handleCancleProcess,
  handleProcessWorkAssignment,
  handleAddAsMaterial,
  handleFIFORequestMaterial,
};
