import React, { useEffect, useState } from 'react';
import { OutTable } from 'react-excel-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  LoadingDialog,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { MQ_TASK } from '@iarcpsu/emanufac-constant';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import TemplateFile from '@/assets/files/CrabAddingTemplate.xlsx';
import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function UploadMaterialStock
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

/**
 * From https://github.com/ashishd751/react-excel-renderer/blob/master/src/index.js
 */
function makeCols(refstr) {
  const o = [];
  const C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (let i = 0; i < C; ++i) {
    o[i] = { name: XLSX.utils.encode_col(i), key: i };
  }
  return o;
}

export function ExcelRenderer(file, callback) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = function (e) {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        cellDates: true,
      });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      const json = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const cols = makeCols(ws['!ref']);

      const data = { rows: json, cols };

      resolve(data);
      return callback(null, data);
    };
    if (file && rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
}

export default function UploadMaterialStock({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputData, setInputData] = useState({});
  const material = useSelector((state) => state.material);
  const [allowUploadToServer, setAllowUploadToServer] = useState(false);
  const me = useSelector((state) => state.me);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [isDialogLoadingOn, setIsDialogLoadingOn] = useState(false);
  const [statusLabel, setStatusLabel] = useState('');

  // FIXME: Use Environment Variable, Config or from Database
  const warehouse2CategoryIds = [
    '6620a248882659bbf9c6f131',
    '6620a287882659bbf9c6f13c',
  ];
  const warehouse3CategoryIds = ['6620c05111eee5c0c746add7'];

  // When File is added to file input
  const fileHandler = (event) => {
    const fileObj = event.target.files[0];
    console.log('FileObject', fileObj);
    // just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setInputData({
          cols: resp.cols,
          rows: resp.rows,
        });
        console.log('Rows', resp.rows);
        setAllowUploadToServer(true);
      }
    });
  };

  // When Click upload to server
  const handleOnFileLoad = async () => {
    try {
      console.log('File Data');
      console.log('-----');

      // ชื่อของ Key จะอยู่ใน แถวที่ index 1 (แถวที่ 2 ใน Excel) และเอาแถวสุดท้ายออก
      const fileData = _.initial(_.slice(inputData?.rows, 2));
      const unNullFileData = _.filter(fileData, (each) => {
        // Filter out empty string
        const uniqueOfEach = _.uniq(each);

        if (_.isEmpty(uniqueOfEach)) {
          return false;
        }
        if (uniqueOfEach?.[0] === undefined && uniqueOfEach?.[1] === '') {
          return false;
        }
        if (_.size(uniqueOfEach) === 1 && uniqueOfEach?.[0] === '') {
          return false;
        }

        return !_.isEmpty(each);
      });
      const firstRow = inputData?.rows?.[0];
      const secondRow = inputData?.rows?.[1];
      const zipColumn = _.zip(firstRow, secondRow);
      // console.log('Zip Column', zipColumn);

      const mapMaterialName = _.map(zipColumn, (column, index) => {
        const [first, second] = column;
        const currentCategoryIds =
          index < 30 ? warehouse2CategoryIds : warehouse3CategoryIds;
        const materialObject = _.find(
          material?.rows,
          (each) =>
            each?.name === second &&
            _.includes(currentCategoryIds, each?.material_type?._id),
        );
        return {
          type: materialObject ? 'material' : 'other',
          materialId: materialObject?._id,
          materialName: materialObject?.name,
          other: first,
          place: index < 30 ? 2 : 3,
        };
      });

      const materialNameInString = _.map(mapMaterialName, (each) => {
        if (each.type === 'material') {
          return `${each?.place}-${each?.materialId}`;
        }
        return each?.other;
      });

      // console.log('File Data', unNullFileData);
      // console.log('Map Material Name', mapMaterialName);
      console.log('Material Name In String', materialNameInString);
      const fileInFormat = _.map(unNullFileData, (_fileData) =>
        _.zipObject(materialNameInString, _fileData),
      );

      console.log('File In Format', fileInFormat);
      setIsDialogLoadingOn(true);
      await dispatch(
        actions.viyacrabMaterialStockLotBatchUpload({
          arr: fileInFormat,
          referenceNumber,
        }),
      );
      // Handle Function
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาดในการอัพโหลดข้อมูล',
        text: error?.message,
      });
    }
  };

  useEffect(() => {
    dispatch(actions.materialAll({ page: 1, size: config.maxFetchSize }));

    return () => {};
  }, []);

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  // Recieve Response From SocketIO
  const socketioHandleFunction = {
    onSuccess: () => {
      setIsDialogLoadingOn(false);
      history.goBack();
    },
    onFail: (args) => {
      setIsDialogLoadingOn(false);
      alert(args?.message);
    },
  };

  // Return Main Component
  if (material.isLoading) {
    return <Loading />;
  }

  if (!material?.isLoading && !material?.isCompleted) {
    return <Error />;
  }

  return (
    <div>
      <ViewTitle title={title} subtitle={subtitle} />
      <BackButton />
      <SocketIOLoading
        referenceNumber={referenceNumber}
        taskCode={MQ_TASK.VIYACRAB_MATERIAL_BATCH_UPLOAD.status_code}
        handleFail={socketioHandleFunction.onFail}
        handleSuccess={socketioHandleFunction.onSuccess}
        setStatusMessage={setStatusLabel}
      />
      <LoadingDialog label={statusLabel} isLoading={isDialogLoadingOn} />
      <div className="my-2">
        <Card>
          <CardContent>
            <div>
              ดาวน์โหลดไฟล์ Template จากที่นี่{' '}
              <Link
                to={TemplateFile}
                target="_blank"
                download
                className="text-blue-700 hover:underline"
              >
                ดาวน์โหลด
              </Link>{' '}
              จากนั้นอัพโหลดไฟล์เข้ามาในระบบ ระบบจะดำเนินการเพิ่มลงในคลังที่ 1-3
              โดยอัตโนมัติ
            </div>
            <div>อัพโหลดไฟล์ข้อมูล</div>
            <input type="file" onChange={fileHandler} className="my-2" />
            <div className="flex justify-end">
              {allowUploadToServer && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleOnFileLoad()}
                >
                  อัพโหลดไปที่เซิฟเวอร์
                </Button>
              )}
            </div>
            {console.log('Input Data', inputData)}
            {/* {inputData?.rows && (
              <OutTable
                data={inputData?.rows}
                columns={inputData?.cols}
                tableClassName="ExcelTable2007"
                tableHeaderRowClass="heading bg-gray-200"
              />
            )} */}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

UploadMaterialStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

UploadMaterialStock.defaultProps = {
  title: '',
  subtitle: '',
};
