import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  BackButton,
  DaySpotAndRangePicker,
  Error,
  Loading,
  ManufacturingOrderExportExcelContainer,
  MiniBoxQuotationStatus,
  NameBox,
  QuotationReport,
  QuotationTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { QUOTATION_STATUS } from '@iarcpsu/emanufac-constant';
import { Button, Card, CardContent, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { api, config } from '@/configs';
import * as actions from '@/redux/actions';
import { QuotationUtils } from '@/utils/excelColumn';
import { useQuery } from '@/utils/functions';

/**
 * @function Quotations
 * @description Display a collections or a list of Quotations from database
 */

export default function QuotationBySales({ title, subtitle }) {
  const dispatch = useDispatch();
  const quotation = useSelector((state) => state.quotation);
  const information = useSelector((state) => state.information);
  const employee = useSelector((state) => state.employee);
  const browserQuery = useQuery();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);
  const [startDate, setStartDate] = useState(
    dayjs(browserQuery.get('startDate')).toDate(),
  );
  const [endDate, setEndDate] = useState(
    dayjs(browserQuery.get('endDate')).toDate(),
  );
  const [groupByStatusData, setGroupByStatusData] = useState([]);
  const [groupByStatusDataIsLoading, setGroupByStatusDataIsLoading] = useState(
    true,
  );
  const searchStatus = QUOTATION_STATUS[browserQuery.get('status')];

  const handlePrintPdf = (row) => {
    try {
      QuotationReport(row, information, true);
    } catch (error) {
      QuotationReport(row, information, false);
    }
  };

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.quotationAll({
          ...(searchStatus?.query || {}),
          page,
          size,
          withLog: true,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
          sales: params.id,
        }),
      );
    } catch (error) {
      dispatch(actions.quotationError());
    }
  };

  const quotationQuery = {
    page: 1,
    size: config.maxFetchSize,
    sales: params.id,
    withLog: true,
    startDate: dayjs(startDate).format('YYYY-MM-DD'),
    endDate: dayjs(endDate).format('YYYY-MM-DD'),
  };

  const queryGroupByDateData = async () => {
    try {
      const groupByStatusQuery = new URLSearchParams({
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        sales: params.id,
      });
      const { data: tempGroupByDateData } = await api.get(
        `${
          process.env.REACT_APP_API_URL
        }/quotation/group-by-status?${groupByStatusQuery.toString()}`,
      );
      setGroupByStatusData(tempGroupByDateData);
      console.log('tempGroupByDateData', tempGroupByDateData);
      setGroupByStatusDataIsLoading(false);
    } catch (error) {
      console.error('Quotation Dashboard Error', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    queryGroupByDateData();
    return () => {};
  }, [page, size, startDate, endDate]);

  useEffect(() => {
    dispatch(actions.employeeGet(params.id));

    return () => {};
  }, []);

  useEffect(() => {
    if (quotation?.rows) {
      setTotal(quotation?.total);
    }

    return () => {};
  }, [quotation]);

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        icon: 'question',
        title: 'ยืนยันการลบใบเสนอราคา',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(actions.quotationDelete(id));
          queryDataFromServer();
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถลบใบเสนอราคา',
        text: error?.message,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (quotation.isLoading) {
    return <Loading />;
  }
  if (!quotation.isLoading && quotation.isCompleted) {
    return (
      <div>
        <div className="flex justify-between items-center">
          {renderTitle()}
          <div>
            <Link
              to={`/spm/manufacturing-order/by-sales/detail/${
                params.id
              }?startDate=${dayjs(startDate).format(
                'YYYY-MM-DD',
              )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`}
            >
              <Button variant="contained" color="secondary">
                คำสั่งผลิตตามพนักงานขาย
              </Button>
            </Link>
          </div>
        </div>
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap justify-between">
                <div>
                  <NameBox user={employee} showDepartment />
                  <div>
                    ตั้งแต่วันที่ {dayjs(startDate).format('D MMMM BBBB')} ถึง{' '}
                    {dayjs(endDate).format('D MMMM BBBB')}
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/6 ml-4 flex justify-end">
                  <ManufacturingOrderExportExcelContainer
                    sheetName="Quotation"
                    dataQuery={quotationQuery}
                    currentData={quotation?.rows}
                    dataAPIEndPoint="quotation"
                    columnList={QuotationUtils.columns}
                    processColumnList={QuotationUtils.columnWithProcess}
                    spreadedColumn="processes"
                  />
                </div>
              </div>
              {groupByStatusDataIsLoading ? (
                <Skeleton />
              ) : (
                <MiniBoxQuotationStatus quotationAnalytic={groupByStatusData} />
              )}
            </CardContent>
          </Card>
        </div>

        <div className="my-2">
          <Card>
            <CardContent>
              <DaySpotAndRangePicker
                enable
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                size="small"
                flex
                defaultUseRange
                anotherQuery="?"
              />
            </CardContent>
          </Card>
        </div>

        <div className="my-2">
          <QuotationTable
            quotation={quotation}
            page={page}
            size={size}
            total={total}
            setPage={setPage}
            setSize={setSize}
            handleDelete={handleDelete}
            handlePrintPdf={handlePrintPdf}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

QuotationBySales.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

QuotationBySales.defaultProps = {
  title: '',
  subtitle: '',
};
