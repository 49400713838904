import dayjs from 'dayjs';

import api from '../../../configs/api';
import graphqlClient from '../../../configs/graphqlClient';
import {
  MANUFACTURING_ORDER_ALL,
  MANUFACTURING_ORDER_DEL,
  MANUFACTURING_ORDER_ERROR,
  MANUFACTURING_ORDER_GET,
  MANUFACTURING_ORDER_LOADING,
  MANUFACTURING_ORDER_POST,
  MANUFACTURING_ORDER_PUT,
} from '../types';

export const manufacturingOrderCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MANUFACTURING_ORDER_POST });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/manufacturing-order`,
      {
        ...payload,
      },
    );
    dispatch({ type: MANUFACTURING_ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    console.log(JSON.stringify(error));
    dispatch({ type: MANUFACTURING_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const manufacturingOrderAll = ({
  name = '',
  size = '',
  page = 1,
  completed = false,
  history = false,
  all = false,
  startDate = '',
  endDate = '',
  sortCreatedAt = '',
  analyzed = '',
  claimed = '',
  productCode = '',
  fetchProduct = '',
  assigner = '',
  assignerDepartment = '',
}) => async (dispatch) => {
  try {
    const { data, status: httpStatus } = await api.get(
      `${
        process.env.REACT_APP_API_URL
      }/manufacturing-order?name=${name}&size=${size}&page=${page}${
        completed ? '' : '&!completed'
      }&history=${history}&all=${all}&startDate=${startDate}&endDate=${endDate}&sort_created_at=${sortCreatedAt}&analyzed=${analyzed}&claimed=${claimed}&productCode=${productCode}&fetchProduct=${fetchProduct}&assigner=${assigner}&assignerDepartment=${assignerDepartment}`,
    );
    if (httpStatus === 200) {
      dispatch({ type: MANUFACTURING_ORDER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const manufacturingOrderAllQuery = ({ query }) => async (dispatch) => {
  try {
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findManufacturingOrders;
    //  console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: MANUFACTURING_ORDER_ERROR });
    }

    dispatch({ type: MANUFACTURING_ORDER_ALL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const manufacturingOrderGetQuery = ({ query }) => async (dispatch) => {
  try {
    console.log('Query', query);
    const queryResult = await graphqlClient.request(query);
    const data = queryResult?.findOneManufacturingOrder;
    console.log('Query Data', queryResult);
    if (!data) {
      dispatch({ type: MANUFACTURING_ORDER_ERROR });
    }

    dispatch({ type: MANUFACTURING_ORDER_GET, payload: data });

    // const { data, status } = await api.get(
    //   `${process.env.REACT_APP_API_URL}/manufacturing-order/${id}`,
    // );
    // if (status === 200) {
    //   dispatch({ type: MANUFACTURING_ORDER_GET, payload: data });
    // }
    // if (status === 404) {
    //   dispatch({ type: MANUFACTURING_ORDER_ERROR, payload: { message: '' } });
    // }
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const manufacturingOrderStateSet = (data) => async (dispatch) => {
  dispatch({ type: MANUFACTURING_ORDER_ALL, payload: data });
};

export const manufacturingOrderStateOneSet = (data) => async (dispatch) => {
  dispatch({ type: MANUFACTURING_ORDER_GET, payload: data });
};

export const manufacturingOrderGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/manufacturing-order/${id}?enableFetchInside=true&fetchCurrentStep=true&fetchCustomer=true&fetchMaterial=true&fetchProduct=true&fetchProcess=true&fetchBaseProject=true`,
    );
    if (status === 200) {
      dispatch({ type: MANUFACTURING_ORDER_GET, payload: data });
    }
    if (status === 404) {
      dispatch({ type: MANUFACTURING_ORDER_ERROR, payload: { message: '' } });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const manufacturingOrderPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MANUFACTURING_ORDER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/manufacturing-order/${id}`,
      payload,
    );
    dispatch({ type: MANUFACTURING_ORDER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const manufacturingOrderReset = () => async (dispatch) => {
  dispatch({ type: MANUFACTURING_ORDER_LOADING });
};

export const manufacturingOrderError = () => async (dispatch) => {
  dispatch({ type: MANUFACTURING_ORDER_ERROR });
};

export const manufacturingOrderDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MANUFACTURING_ORDER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/manufacturing-order/${id}`,
    );
    dispatch({ type: MANUFACTURING_ORDER_DEL, payload: data });
  } catch (error) {
    const { status } = error.request;
    console.error(error?.response?.data?.error?.message);

    if (status === 403) {
      // prettier-ignore
      const message = 'ไม่สามารถลบข้อมูลเนื่องจากยังคงมีรายการที่ใช้สถานะนี้อยู่';
      dispatch({ type: MANUFACTURING_ORDER_ERROR, payload: { message } });
      throw new Error(message);
    } else {
      const message = 'เกิดข้อผิดพลาด';
      dispatch({ type: MANUFACTURING_ORDER_ERROR, payload: { message } });
      throw new Error(message);
    }
  }
};

export const manufacturingOrderAudit = ({ referenceNumber }) => async (
  dispatch,
) => {
  try {
    dispatch({ type: MANUFACTURING_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/manufacturing-order/audit-log`,
      {
        referenceNumber,
      },
    );
    dispatch({ type: MANUFACTURING_ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const manufacturingOrderForceAudit = ({
  referenceNumber,
  selectedTime,
}) => async (dispatch) => {
  try {
    dispatch({ type: MANUFACTURING_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/manufacturing-order/force-audit`,
      {
        referenceNumber,
        selectedTime,
      },
    );
    dispatch({ type: MANUFACTURING_ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MANUFACTURING_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
