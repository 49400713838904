import api from '../../configs/api';

export default class BaseAction {
  constructor(
    subURL,
    {
      allConst,
      getConst,
      postConst,
      putConst,
      deleteConst,
      errorConst,
      loadingConst,
    },
  ) {
    this.subURL = subURL;
    this.allConst = allConst;
    this.getConst = getConst;
    this.postConst = postConst;
    this.putConst = putConst;
    this.deleteConst = deleteConst;
    this.errorConst = errorConst;
    this.loadingConst = loadingConst;
  }

  create(payload) {
    return async (dispatch) => {
      try {
        dispatch({ type: this.loadingConst });
        const { data } = await api.post(
          `${process.env.REACT_APP_API_URL}/${this.subURL}`,
          {
            ...payload,
          },
        );
        dispatch({ type: this.postConst, payload: data });
      } catch (error) {
        console.error(error);
        dispatch({ type: this.errorConst });
        throw new Error(error?.response?.data?.error?.message);
      }
    };
  }

  getAllData({ page = 1, size = 10, name = '', ...query }) {
    return async (dispatch) => {
      try {
        dispatch({ type: this.loadingConst });
        const queryParams = new URLSearchParams({ page, size, name, ...query });
        const { data, status } = await api.get(
          `${process.env.REACT_APP_API_URL}/${
            this.subURL
          }?${queryParams.toString()}`,
        );

        if (status === 200) {
          dispatch({ type: this.allConst, payload: data });
        } else {
          dispatch({
            type: this.errorConst,
            payload: { error: `Get ${this.subURL} Error` },
          });
        }
      } catch (error) {
        console.error(error);
        dispatch({ type: this.errorConst });
        throw new Error(error?.response?.data?.error?.message);
      }
    };
  }

  getOneData(id) {
    return async (dispatch) => {
      try {
        dispatch({ type: this.loadingConst });
        const { data, status } = await api.get(
          `${process.env.REACT_APP_API_URL}/${this.subURL}/${id}`,
        );
        if (status === 200) {
          dispatch({ type: this.getConst, payload: data });
        }
      } catch (error) {
        console.error(error);
        dispatch({ type: this.errorConst });
        throw new Error(error?.response?.data?.error?.message);
      }
    };
  }

  stateSet(data) {
    return async (dispatch) => {
      dispatch({ type: this.allConst, payload: data });
    };
  }

  stateOneSet(data) {
    return async (dispatch) => {
      dispatch({ type: this.getConst, payload: data });
    };
  }

  reset() {
    return async (dispatch) => {
      dispatch({ type: this.loadingConst });
    };
  }

  onError() {
    return async (dispatch) => {
      dispatch({ type: this.errorConst });
    };
  }

  editOne(id, payload) {
    return async (dispatch) => {
      try {
        dispatch({ type: this.loadingConst });
        const { data } = await api.put(
          `${process.env.REACT_APP_API_URL}/${this.subURL}/${id}`,
          payload,
        );
        dispatch({ type: this.putConst, payload: data });
      } catch (error) {
        console.error(error);
        dispatch({ type: this.errorConst });
        throw new Error(error?.response?.data?.error?.message);
      }
    };
  }

  deleteOne(id) {
    return async (dispatch) => {
      try {
        dispatch({ type: this.loadingConst });
        const { data } = await api.delete(
          `${process.env.REACT_APP_API_URL}/${this.subURL}/${id}`,
        );
        dispatch({ type: this.deleteConst, payload: data });
      } catch (error) {
        console.error(error);
        dispatch({ type: this.errorConst });
        throw new Error(error?.response?.data?.error?.message);
      }
    };
  }
}
