import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import {
  Button,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function ShippingRound
 * @description Display a collections or a list of ShippingRound from database
 */

export default function ShippingRounds({ title, subtitle }) {
  const dispatch = useDispatch();
  const shippingRound = useSelector((state) => state.shippingRound);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [total, setTotal] = useState(undefined);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.shippingRoundAll({ page, size, name }));
    } catch (error) {
      dispatch(actions.shippingRoundError());
    }
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการลบข้อมูลหรือไม่',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(actions.shippingRoundDelete(id));
          await queryDataFromServer();
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ลบไม่สำเร็จ',
        text: error.message,
      });
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (shippingRound?.rows) {
      setTotal(shippingRound?.total);
    }

    return () => {};
  }, [shippingRound]);

  const renderTitle = () => (
    <div className="flex flex-wrap items-center justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
      <Link to="/setting/spm/shipping-round/create">
        <Button variant="contained">เพิ่ม</Button>
      </Link>
    </div>
  );

  if (shippingRound.isLoading) {
    return <Loading />;
  }
  if (!shippingRound.isLoading && shippingRound.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <Card>
            <div className="w-full mx-4 my-2 flex flex-wrap">
              <div className="lg:w-1/2 w-full">
                <TextField
                  fullWidth
                  size="small"
                  label="ค้นหา"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ชื่อรอบจัดส่ง</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">รายละเอียด</div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">ดำเนินาร</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(shippingRound?.rows) ? (
                    _.map(shippingRound?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">
                            {(page - 1) * size + index + 1}
                          </div>
                        </TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>{row?.description}</TableCell>
                        <TableCell>
                          <div className="flex gap-2">
                            <Link
                              to={`/setting/spm/shipping-round/edit/${row?.id}`}
                            >
                              <Button
                                size="small"
                                variant="contained"
                                color="warning"
                              >
                                แก้ไข
                              </Button>
                            </Link>
                            <Button
                              size="small"
                              variant="contained"
                              color="error"
                              onClick={() => handleDelete(row?.id)}
                            >
                              ลบ
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={(event, newPage) => setPage(newPage + 1)}
              onRowsPerPageChange={(event) => {
                setSize(event.target.value);
                setPage(1);
              }}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

ShippingRounds.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ShippingRounds.defaultProps = {
  title: '',
  subtitle: '',
};
