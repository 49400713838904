import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  ShippingRoundForm,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function EditShippingRound
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function EditShippingRound({ title, subtitle }) {
  const dispatch = useDispatch();
  const shippingRound = useSelector((state) => state.shippingRound);
  const history = useHistory();
  const params = useParams();

  const { control, handleSubmit } = useForm();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.shippingRoundGet(params.id));
    } catch (error) {
      dispatch(actions.shippingRoundError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleSubmitFunction = async (data) => {
    try {
      await dispatch(actions.shippingRoundPut(params.id, data));
      Swal.fire({
        icon: 'success',
        title: 'แก้ไขรอบจัดส่งสำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `เกิดข้อผิดพลาด ${error?.message}`,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (shippingRound.isLoading) {
    return <Loading />;
  }

  if (
    !shippingRound.isLoading &&
    shippingRound.isCompleted &&
    !shippingRound.rows
  ) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleSubmitFunction)}>
            <Card>
              <CardContent>
                <ShippingRoundForm
                  control={control}
                  shippingRound={shippingRound}
                />
              </CardContent>
            </Card>
            <div className="flex justify-end mt-2">
              <Button type="submit" variant="contained" color="primary">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditShippingRound.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditShippingRound.defaultProps = {
  title: '',
  subtitle: '',
};
