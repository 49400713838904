import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Error,
  Loading,
  NameBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import {
  Autocomplete,
  Button,
  Card,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function QuotationEmployeeList
 * @description Display a collections or a list of QuotationEmployeeList from database
 */

export default function QuotationEmployeeList({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const department = useSelector((state) => state.department);
  const query = useQuery();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [total, setTotal] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.employeeAll({
          page,
          size,
          name,
          selectDepartment: selectedDepartment?._id || '',
        }),
      );
      dispatch(
        actions.departmentAll({
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      dispatch(actions.employeeError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    if (employee) {
      setTotal(employee?.total);
    }

    return () => {};
  }, [employee]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (employee.isLoading) {
    return <Loading />;
  }
  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <Card>
            <div className="p-4 flex flex-row">
              <div className="w-full md:w-1/2">
                <TextField
                  label="ค้นหา"
                  fullWidth
                  size="small"
                  id="outlined-start-adornment"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="fas fa-search"></i>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="w-full md:w-1/4 px-2">
                {!_.isEmpty(department?.rows) && (
                  <Autocomplete
                    disablePortal
                    options={department?.rows}
                    size="small"
                    placeholder="เลือกแผนก"
                    onChange={(e, newValue) => {
                      setSelectedDepartment(newValue);
                    }}
                    className="z-40"
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name || ''}`
                  }
                    renderInput={(params) => (
                      <TextField label="เลือกแผนก" {...params} />
                    )}
                  />
                )}
              </div>
            </div>
          </Card>
        </div>
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">พนักงาน</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(employee?.rows) ? (
                    _.map(employee?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">
                            {(page - 1) * size + index + 1}
                          </div>
                        </TableCell>
                        <TableCell>
                          <NameBox user={row} showDepartment />
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/spm/quotation/by-sales/${
                              row?._id
                            }?startDate=${
                              query.get('startDate') || ''
                            }&endDate=${query.get('endDate') || ''}&status=${
                              query.get('status') || ''
                            }`}
                          >
                            <Button color="info" variant="contained">
                              ใบเสนอราคา
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={(e, newPage) => setPage(newPage + 1)}
              onRowsPerPageChange={(e) => setSize(e.target.value)}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

QuotationEmployeeList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

QuotationEmployeeList.defaultProps = {
  title: '',
  subtitle: '',
};
