/* eslint-disable arrow-body-style */
import React from 'react';
import { Edit } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { currencyFormatter } from '@iarcpsu/emanufac-utils/functions';
import { Button, Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const CardDeposit = ({ currentProcess }) => {
  const information = useSelector((state) => state.information);
  return (
    <div className="mb-2">
      <Card>
        <CardContent>
          <div className="flex justify-between items-center">
            <h4 className="font-bold font-display">การมัดจำ </h4>
            {information?.setting?.mms?.process?.addDeposit &&
              currentProcess?.deposit?.is_deposit && (
                <div className="flex gap-1">
                  <Link to={`/mms/process/deposit/edit/${currentProcess?._id}`}>
                    <Button startIcon={<Edit size={16} />}>แก้ไข</Button>
                  </Link>
                </div>
              )}
          </div>
          {currentProcess?.deposit?.is_deposit ? (
            <div>
              <div className="flex gap-1 items-center my-2 rounded-md p-1">
                <i className="fas fa-check-circle text-green-500" />
                มัดจำแล้ว
              </div>
              <div>
                จำนวน{' '}
                {currencyFormatter.format(currentProcess?.deposit?.amount || 0)}{' '}
                บาท
              </div>
              <div>
                เมื่อวันที่{' '}
                {dayjs(currentProcess?.deposit?.deposit_date).format(
                  'D MMM BBBB',
                )}
              </div>
              {information?.setting?.mms?.manufacturingOrder?.addDeposit && (
                <div className="text-sm">
                  ประมาณตามสัดส่วนของเงินมัดจำทั้งหมด
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="my-1">ยังไม่มีการมัดจำ</div>
              {information?.setting?.mms?.process?.addDeposit && (
                <div className="my-2">
                  <Link to={`/mms/process/deposit/${currentProcess?._id}`}>
                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<i className="fas fa-wallet" />}
                    >
                      มัดจำ
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default CardDeposit;

CardDeposit.propTypes = {
  currentProcess: PropTypes.object,
};
