import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DesktopDatepicker } from '../../DatePicker';
import { ImageUpload } from '../../ImageUpload';

const ProcessDeliveryForm = ({
  control,
  defaultDelivery,
  shippingType,
  showDeliverySwitch,
  image,
  shippingRound,
  setImage = () => {},
  showImageUpload = true,
}) => (
  <div className="flex flex-wrap">
    {showDeliverySwitch && (
      <div className="w-full px-2 py-1">
        <Controller
          control={control}
          defaultValue={defaultDelivery?.delivered}
          name="delivery.delivered"
          render={({ field }) => (
            <FormControlLabel
              label="จัดส่งแล้ว"
              control={<Switch {...field} defaultChecked={field.value} />}
            />
          )}
        />
      </div>
    )}
    <div className="w-full px-2 py-1">
      <Controller
        control={control}
        defaultValue={defaultDelivery?.delivery_date || new Date()}
        name="delivery.delivery_date"
        render={({ field }) => (
          <DesktopDatepicker
            label="วันที่จัดส่ง"
            {...field}
            size="small"
            fullWidth
          />
        )}
      />
    </div>

    <div className="w-full px-2 py-1">
      {!_.isEmpty(shippingType?.rows) && (
        <Controller
          control={control}
          defaultValue={defaultDelivery?.delivery_method}
          name="delivery.delivery_method"
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              options={shippingType?.rows}
              size="small"
              placeholder="รูปแบบการจัดส่ง"
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              className="z-40"
              // prettier-ignore
              getOptionLabel={(option) => `${option?.name || ''}`
          }
              renderInput={(params) => (
                <TextField label="รูปแบบการจัดส่ง" {...params} />
              )}
            />
          )}
        />
      )}
    </div>

    <div className="w-full px-2 py-1">
      {!_.isEmpty(shippingRound?.rows) && (
        <Controller
          control={control}
          defaultValue={defaultDelivery?.shipping_round}
          name="delivery.shipping_round"
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              options={shippingRound?.rows}
              size="small"
              placeholder="รอบการจัดส่ง"
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              className="z-40"
              // prettier-ignore
              getOptionLabel={(option) => `${option?.name || ''}`
        }
              renderInput={(params) => (
                <TextField label="รอบการจัดส่ง" {...params} />
              )}
            />
          )}
        />
      )}
    </div>
    <div className="w-full px-2 py-1">
      <Controller
        control={control}
        defaultValue={defaultDelivery?.delivery_remark}
        name="delivery.delivery_remark"
        render={({ field }) => (
          <TextField {...field} fullWidth label="หมายเหตุ" multiline rows={3} />
        )}
      />
    </div>
    {showImageUpload && (
      <div className="w-full px-2 py-1">
        <div>อัพโหลดหลักฐานการจัดส่ง</div>
        <div className="w-full  px-1 py-2">
          <ImageUpload
            images={image}
            maxNumber={1}
            previewSize={400}
            setImages={setImage}
          />
        </div>{' '}
        <div className="w-full lg:w-1/2  px-1 py-2">
          <img src={defaultDelivery?.image?.url} className="h-24" />
        </div>
      </div>
    )}
  </div>
);

export default ProcessDeliveryForm;

ProcessDeliveryForm.propTypes = {
  control: PropTypes.object,
  defaultDelivery: PropTypes.object,
  shippingType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  shippingRound: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  showDeliverySwitch: PropTypes.bool,
  image: PropTypes.array,
  setImage: PropTypes.func,
  showImageUpload: PropTypes.bool,
};
