import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function DashboardEmployeeSelect
 * @description Display a collections or a list of DashboardEmployeeSelect from database
 */

export default function DashboardAssignerDepartmentSelector({
  title,
  subtitle,
}) {
  const dispatch = useDispatch();
  const department = useSelector((state) => state.department);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [total, setTotal] = useState();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.departmentAll({ page, size, name }));
    } catch (error) {
      dispatch(actions.employeeError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    if (department?.rows) {
      setTotal(department?.total);
    }

    return () => {};
  }, [department]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (department.isLoading) {
    return <Loading />;
  }
  if (!department.isLoading && department.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <Card>
          <div className="p-4 flex flex-row">
            <div className="w-full md:w-1/2">
              <TextField
                label="ค้นหา"
                fullWidth
                size="small"
                id="outlined-start-adornment"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <i className="fas fa-search"></i>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </Card>
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>

                    <TableCell>
                      <div className="font-bold">แผนก</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(department?.rows) ? (
                    _.map(department?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">
                            {(page - 1) * size + index + 1}
                          </div>
                        </TableCell>
                        <TableCell>{row?.name}</TableCell>

                        <TableCell>
                          <Link
                            to={`/mms/dashboard/assigner-department/detail/${row?._id}`}
                          >
                            <Button variant="contained">รายละเอียด</Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={(event, newPage) => {
                console.log('New Page', page);
                setPage(newPage + 1);
              }}
              onRowsPerPageChange={(event) => {
                setSize(parseInt(event.target.value, 10));
                setPage(1);
              }}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

DashboardAssignerDepartmentSelector.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DashboardAssignerDepartmentSelector.defaultProps = {
  title: '',
  subtitle: '',
};
