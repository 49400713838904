import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ProductAmountInput from '../../Inputs/ProdOrMatQuantityInput';

/**
 * @function ProductAndMaterialSelectForm
 * @memberof Form/MMS
 * @description ฟอร์มย่อยใน Create Manufacturing Order ที่เกี่ยวการการเพิ่มสินค้า/วัตถุดิบ แต่ละชิ้น
 * ในการผลิต 1 คำสั่งผลิต
 * @returns {React.Component} Form Component
 */
export function QuotationProductsForm({
  errors,
  control,
  product,
  watch,
  material,
  manufacturingOrder,
  information,
  setValue,
  isReady,
  disableAddButton = false,
  baseProject,
  showInputPrice,
}) {
  const [reload, setReload] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'processes',
  });

  const {
    setting: { mms: mmsSetting },
  } = information;

  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สินค้า</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ปริมาณ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายละเอียด</div>
                </TableCell>
                {showInputPrice && (
                  <TableCell>
                    <div className="font-bold">ราคา</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold">ลบ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((_field, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {isReady && mmsSetting?.allowProduceMaterial && (
                      <div className="w-full px-1 py-2 flex">
                        <div className="">
                          <Controller
                            control={control}
                            name={`processes[${index}].produce_material_instead`}
                            defaultValue={
                              watch(
                                `processes[${index}].produce_material_instead`,
                              ) || false
                            }
                            render={({ field }) => (
                              <FormControl>
                                <div className="flex gap-2">
                                  <div className="self-center text-black font-system">
                                    ผลิตสินค้า
                                  </div>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        {...field}
                                        onChange={() => {
                                          field.onChange(!field?.value);
                                          setValue(
                                            `processes[${index}].createFromTemplate`,
                                            true,
                                          );
                                        }}
                                        defaultChecked={field?.value}
                                        defaultValue={field?.value}
                                      />
                                    }
                                    label="ผลิตวัตถุดิบ"
                                  />
                                </div>
                              </FormControl>
                            )}
                          />
                        </div>
                      </div>
                    )}
                    {isReady &&
                      !mmsSetting?.allowUseBaseProject &&
                      !watch(`processes[${index}].produce_material_instead`) &&
                      (!product?.isLoading && product?.isCompleted ? (
                        <div className="w-full   px-1 py-2">
                          <Controller
                            name={`processes[${index}].product`}
                            control={control}
                            rules={{ required: true }}
                            defaultValue={
                              watch(`processes[${index}].product`) ||
                              product?.rows?.[0]
                            }
                            render={({ field }) => (
                              <Autocomplete
                                size={'small'}
                                disableClearable
                                disabled={manufacturingOrder?.process?.[index]}
                                {...field}
                                onChange={(e, newValue) => {
                                  field.onChange(newValue);
                                  setReload(!reload);
                                }}
                                options={_.map(
                                  product?.rows,
                                  (eachProduct, prodIndex) => ({
                                    ...eachProduct,
                                    index: prodIndex,
                                  }),
                                )}
                                getOptionLabel={(option) => {
                                  if (option?.type_code) {
                                    return ` ${option?.type_code || ''}  ${
                                      option?.name
                                    }`;
                                  }
                                  return ` ${option?.name}`;
                                }}
                                required
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="สินค้า"
                                    required
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      ) : (
                        <LinearProgress />
                      ))}{' '}
                    {watch(`processes[${index}].produce_material_instead`) &&
                      !mmsSetting?.allowUseBaseProject && (
                        <div className="w-full   px-1 py-2">
                          <Controller
                            name={`processes[${index}].product_as_material`}
                            control={control}
                            rules={{ required: true }}
                            defaultValue={
                              watch(
                                `processes[${index}].product_as_material`,
                              ) || material?.[0]
                            }
                            render={({ field }) => (
                              <Autocomplete
                                size={'small'}
                                disableClearable
                                disabled={manufacturingOrder?.process?.[index]}
                                {...field}
                                onChange={(e, newValue) => {
                                  field.onChange(newValue);
                                  setReload(!reload);
                                }}
                                options={_.map(
                                  material,
                                  (eachMaterial, matIndex) => ({
                                    ...eachMaterial,
                                    index: matIndex,
                                  }),
                                )}
                                getOptionLabel={(option) => {
                                  if (option?.type_code) {
                                    return `${option?.index || ''} ${
                                      option?.type_code || ''
                                    } | ${option?.name}`;
                                  }
                                  return `${option?.index || ''} ${
                                    option?.name
                                  }`;
                                }}
                                required
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="วัตถุดิบที่เป็นผลผลิต"
                                    required
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      )}
                    {mmsSetting?.allowUseBaseProject &&
                      !_.isEmpty(baseProject?.rows) && (
                        <div className="w-full   px-1 py-2">
                          <Controller
                            name={`processes[${index}].base_project`}
                            control={control}
                            rules={{ required: true }}
                            defaultValue={
                              watch(`processes[${index}].base_project`) || null
                            }
                            render={({ field }) => (
                              <Autocomplete
                                size={'small'}
                                disableClearable
                                disabled={manufacturingOrder?.process?.[index]}
                                {...field}
                                onChange={(e, newValue) => {
                                  field.onChange(newValue);
                                  setReload(!reload);
                                }}
                                options={_.map(
                                  baseProject?.rows,
                                  (eachBaseProject, baseProjectIndex) => ({
                                    ...eachBaseProject,
                                    index: baseProjectIndex,
                                  }),
                                )}
                                getOptionLabel={(option) => {
                                  if (option?.type_code) {
                                    return `${option?.type_code || ''}  ${
                                      option?.name
                                    }`;
                                  }
                                  return `${option?.name}`;
                                }}
                                required
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="โปรเจกต์/สินค้าพื้นฐาน"
                                    required
                                  />
                                )}
                              />
                            )}
                          />
                          <div>
                            {watch(`processes[${index}].base_project`) && (
                              <div className="my-2">
                                <Alert severity="info">
                                  <div className="font-semibold">
                                    {
                                      watch(`processes[${index}].base_project`)
                                        ?.name
                                    }
                                  </div>
                                  {watch(`processes[${index}].base_project`)
                                    ?.attach_process_template && (
                                    <div className="">
                                      <div>
                                        สูตรการผลิต{' '}
                                        {
                                          watch(
                                            `processes[${index}].base_project`,
                                          )?.process_template?.name
                                        }
                                      </div>
                                    </div>
                                  )}
                                  {watch(`processes[${index}].base_project`)
                                    ?.attach_bom_template && (
                                    <div className="">
                                      <div>
                                        สูตร BOM{' '}
                                        {
                                          watch(
                                            `processes[${index}].base_project`,
                                          )?.bom_template?.name
                                        }
                                      </div>
                                    </div>
                                  )}
                                </Alert>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    <Controller
                      name={`processes[${index}].remark`}
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="รายละเอียด"
                          fullWidth
                          size="small"
                          multiline
                          rows={3}
                        />
                      )}
                    />{' '}
                  </TableCell>{' '}
                  <TableCell>
                    <div className="w-full flex flex-wrap ">
                      {!mmsSetting?.allowUseBaseProject &&
                        watch(`processes[${index}].noQuantity`) === false &&
                        !watch(
                          `processes[${index}].produce_material_instead`,
                        ) && (
                          <div className="w-full  px-1 py-2">
                            <ProductAmountInput
                              control={control}
                              index={index}
                              reload={reload}
                              setReload={setReload}
                              watch={watch}
                              setValue={setValue}
                              selectedProduct={watch(
                                `processes[${index}].product`,
                              )}
                              controllerName={`processes[${index}].quantity`}
                              artificialControllerName={`processes[${index}].quantity_articial`}
                              selectedUnitControllerName={`processes[${index}].selected_unit`}
                            />
                          </div>
                        )}
                      {!mmsSetting?.allowUseBaseProject &&
                        watch(`processes[${index}].noQuantity`) === false &&
                        watch(
                          `processes[${index}].produce_material_instead`,
                        ) && (
                          <div className="w-full  px-1 py-2">
                            <ProductAmountInput
                              control={control}
                              index={index}
                              reload={reload}
                              setReload={setReload}
                              watch={watch}
                              setValue={setValue}
                              selectedProduct={watch(
                                `processes[${index}].product_as_material`,
                              )}
                              controllerName={`processes[${index}].quantity`}
                              artificialControllerName={`processes[${index}].pam_quantity_articial`}
                              selectedUnitControllerName={`processes[${index}].pam_selected_unit`}
                            />
                          </div>
                        )}
                      {mmsSetting?.allowUseBaseProject &&
                        watch(`processes[${index}].noQuantity`) === false && (
                          <div className="w-full  px-1 py-2">
                            <ProductAmountInput
                              control={control}
                              index={index}
                              reload={reload}
                              setReload={setReload}
                              watch={watch}
                              setValue={setValue}
                              selectedProduct={watch(
                                `processes[${index}].base_project`,
                              )}
                              controllerName={`processes[${index}].quantity`}
                              artificialControllerName={`processes[${index}].bp_quantity_articial`}
                              selectedUnitControllerName={`processes[${index}].bp_selected_unit`}
                            />
                          </div>
                        )}
                    </div>
                    {watch('express') === true && (
                      <div className="w-full  px-1 py-2">
                        <Controller
                          name={`processes[${index}].added_percent`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="เปอร์เซ็นต์ที่เพิ่มราคาไป"
                              fullWidth
                              size="small"
                              inputProps={{
                                min: 0,
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <div className="w-full mx-1">
                      <Controller
                        name={`processes[${index}].noQuantity`}
                        defaultValue={mmsSetting?.usingAsDefault?.attachAmount}
                        control={control}
                        //   defaultChecked={}
                        render={({ field }) => (
                          <FormControl>
                            <FormControlLabel
                              label="ไม่ระบุจำนวน"
                              control={
                                <Checkbox {...field} checked={field?.value} />
                              }
                            />
                          </FormControl>
                        )}
                      />
                    </div>
                    <div className="w-full mx-1">
                      <Controller
                        name={`processes[${index}].changeUnit`}
                        control={control}
                        //   defaultChecked={}
                        render={({ field }) => (
                          <FormControl>
                            <FormControlLabel
                              label="เปลี่ยนหน่วยสินค้า"
                              control={
                                <Checkbox {...field} checked={field?.value} />
                              }
                            />
                          </FormControl>
                        )}
                      />
                    </div>
                    {watch(`processes[${index}].changeUnit`) === true && (
                      <div className="w-full px-1 py-2">
                        <Controller
                          name={`processes[${index}].additional_unit`}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="หน่วยสินค้า"
                              fullWidth
                              size="small"
                              helperText={
                                errors.additional_unit && 'กรุณาใส่ข้อมูล'
                              }
                            />
                          )}
                        />
                      </div>
                    )}
                  </TableCell>{' '}
                  {showInputPrice && (
                    <TableCell>
                      <div className="my-1">
                        <Controller
                          name={`processes[${index}].price_per_unit`}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setReload(!reload);
                              }}
                              label="ราคาต่อหน่วย"
                              fullWidth
                              defaultValue={1}
                              inputProps={{
                                min: 0,
                                style: { textAlign: 'center' },
                              }}
                              size="small"
                            />
                          )}
                        />{' '}
                      </div>
                      <TextField
                        label="ราคารวม"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                        value={(
                          (parseFloat(watch(`processes[${index}].quantity`)) ||
                            1) *
                          parseFloat(
                            watch(`processes[${index}].price_per_unit`) || 0,
                          )
                        ).toFixed(2)}
                        size="small"
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => remove(index)}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {!disableAddButton && (
        <div className="flex my-2">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              append({
                product: product?.rows[0],
                quantity: 1,
                materials: [{ material: material[0], begin: 1 }],
                noQuantity: false,
              });
            }}
          >
            เพิ่ม
          </Button>
        </div>
      )}
    </div>
  );
}

QuotationProductsForm.propTypes = {
  errors: PropTypes.object,
  material: PropTypes.object,
  product: PropTypes.object,
  control: PropTypes.object,
  watch: PropTypes.func,
  manufacturingOrder: PropTypes.object,
  information: PropTypes.object,
  setValue: PropTypes.func,
  isReady: PropTypes.bool,
  disableAddButton: PropTypes.bool,
  bomTemplate: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  baseProject: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default QuotationProductsForm;
