import DashboardAssigner from './DashboardAssigner';
import DashboardAssignerDepartment from './DashboardAssignerDepartment';
import DashboardAssignerDepartmentSelector from './DashboardAssignerDepartmentSelector';
import DashboardAssignerSelector from './DashboardAssignerSelector';
import DashboardEmployeeSelect from './DashboardEmployeeSelect';
import DetailReportMaterialUsage from './DetailReportMaterialUsage';
import EmployeeDashboard from './EmployeeDashboard';
import ManufacturingDashboard from './ManufacturingDashboard';
import ManufacturingOrderCalendar from './ManufacturingOrderCalendar';
import OnMonthReportManufacturingOrder from './OnMonthReportManufacturingOrder';
import OtherDashboard from './OtherDashboard';
import ReportAllBom from './ReportAllBom';
import ReportManufacturingOrder from './ReportManufacturingOrder';
import ReportMaterialUsage from './ReportMaterialUsage';

export {
  DashboardAssigner,
  DashboardAssignerDepartment,
  DashboardAssignerDepartmentSelector,
  DashboardAssignerSelector,
  DashboardEmployeeSelect,
  DetailReportMaterialUsage,
  EmployeeDashboard,
  ManufacturingDashboard,
  ManufacturingOrderCalendar,
  OnMonthReportManufacturingOrder,
  OtherDashboard,
  ReportAllBom,
  ReportManufacturingOrder,
  ReportMaterialUsage,
};

export default {
  DetailReportMaterialUsage,
  ReportManufacturingOrder,
  ReportMaterialUsage,
  OnMonthReportManufacturingOrder,
  ReportAllBom,
  ManufacturingDashboard,
  DashboardEmployeeSelect,
  EmployeeDashboard,
  ManufacturingOrderCalendar,
  DashboardAssignerDepartment,
  DashboardAssignerDepartmentSelector,
  OtherDashboard,
  ReportMaterialUsage,
  DashboardAssigner,
  DashboardAssignerSelector,
};
