// IMS
import { DailyOrMonthyPayrollReport } from './DailyOrMonthyPayrollReport';
import DepositReport from './DepositReport';
import { DetailMaterialHTypeAddReport } from './DetailMaterialHTypeAddReport';
import { DetailMaterialHTypeDescReport } from './DetailMaterialHTypeDescReport';
import { DetailProductHTypeAddReport } from './DetailProductHTypeAddReport';
import { DetailProductHTypeDescReport } from './DetailProductHTypeDescReport';
import { DetailReportMaterialReport } from './DetailReportMaterialUsageReport';
// PAMS
import { EmployeePayrollReport } from './EmployeePayrollReport';
import InternalDepositReport from './InternalDepositReport';
// MMS
import { ManufacturingOrderReport } from './ManufacturingOrderReport';
import { MaterialHReport } from './MaterialHReport';
import { MaterialLotReport } from './MaterialLotReport';
import { OneEmployeePayrollReport } from './OneEmployeePayrollReport';
// SPM
import { OrderReport } from './OrderReport';
import { ProcessReport } from './ProcessReport';
// WMS
import { ProductHReport } from './ProductHReport';
import { ProductLotReport } from './ProductLotReport';
// PMS
import { PurchaseOrderReport } from './PurchaseOrderReport';
import { QuotationReport } from './QuotationReport';
import ReportRendering from './ReportRendering';

export {
  DailyOrMonthyPayrollReport,
  DepositReport,
  DetailMaterialHTypeAddReport,
  DetailMaterialHTypeDescReport,
  DetailProductHTypeAddReport,
  DetailProductHTypeDescReport,
  DetailReportMaterialReport,
  // PAMS
  EmployeePayrollReport,
  InternalDepositReport,
  // MMS
  ManufacturingOrderReport,
  // IMS
  MaterialHReport,
  MaterialLotReport,
  OneEmployeePayrollReport,
  // SPM
  OrderReport,
  ProcessReport,
  // WMS
  ProductHReport,
  ProductLotReport,
  // PMS
  PurchaseOrderReport,
  QuotationReport,
  ReportRendering,
};

export default {
  // IMS
  MaterialHReport,
  DetailMaterialHTypeAddReport,
  DetailMaterialHTypeDescReport,
  MaterialLotReport,
  // WMS
  ProductHReport,
  DetailProductHTypeDescReport,
  DetailProductHTypeAddReport,
  ProductLotReport,
  DepositReport,
  // PMS
  PurchaseOrderReport,
  // SPM
  OrderReport,
  QuotationReport,
  InternalDepositReport,
  // MMS
  ManufacturingOrderReport,
  ProcessReport,
  DetailReportMaterialReport,
  // PAMS
  EmployeePayrollReport,
  DailyOrMonthyPayrollReport,
  OneEmployeePayrollReport,
  ReportRendering,
};
