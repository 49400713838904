import _ from 'lodash';

const filterOnlyChildMaterial = ({
  currentLotMaterial,
  filterLotOnPlace,
  allowCategory,
}) => {
  let filterAllowanceMaterial;
  // กรณีเป็น Work in process เอามาเฉพาะที่สืบอดมาได้เท่านั้น
  if (currentLotMaterial?.is_workin_process) {
    const inputMaterialList = _.map(
      currentLotMaterial?.input_materials,
      (each) => each?.material?.toString(),
    );

    filterAllowanceMaterial = _.filter(
      filterLotOnPlace,
      (each) =>
        _.includes(allowCategory, each?.material?.material_type?.toString()) &&
        (_.includes(inputMaterialList, each?.material?._id?.toString()) ||
          each?.material?._id?.toString() ===
            currentLotMaterial?._id?.toString()),
    );
    // กรณีไม่เป็น WIP เอามาทุกตัว
  } else {
    filterAllowanceMaterial = _.filter(filterLotOnPlace, (each) =>
      _.includes(allowCategory, each?.material?.material_type?.toString()),
    );
  }

  return filterAllowanceMaterial;
};

export default filterOnlyChildMaterial;
