/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';

import currencyFormatterTH from '@/utils/functions/currencyFormatterTH';

import 'addthaifont-pdfmake/build/vfs_fonts';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const genOrder = (data, information) =>
  _.map(_.filter(data, { deleted: false }), (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
      alignment: 'center',
    },
    {
      text: `ค่ามัดจำสำหรับผลิต${
        _data?.produce_material_instead
          ? _data?.product_as_material?.type_code || ''
          : _data?.product?.type_code || ''
      } ${
        _data?.produce_material_instead
          ? _data?.product_as_material?.name || ''
          : _data?.product?.name || ''
      } ${
        (_data?.produce_base_project_instead &&
          _data?.base_project?.type_code) ||
        ''
      } ${
        (_data?.produce_base_project_instead && _data?.base_project?.name) || ''
      }  `,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 2,
    },
    {},
    {
      text: `${currencyFormatterTH.format(_data?.deposit?.amount || 0) || ''}`,
      border: [true, false, true, true],
      alignment: 'right',
      fontSize: 10,
      colSpan: 1,
    },
  ]);

const DepositReport = (
  manufacturingOrder,
  information,
  pictureError = false,
) => {
  const orderData = genOrder(manufacturingOrder?.process, information);

  const totalPrice = manufacturingOrder?.deposit?.amount || 0;

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: 'ใบมัดจำ / ใบกำกับภาษี',
    },
    content: [
      pictureError
        ? ReportHeader(information, false)
        : ReportHeader(information, true),
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 10],
        text: 'ใบมัดจำ / ใบกำกับภาษี',
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['10%', '40%', '18%', '*'],
          heights: [15],
          body: [
            [
              {
                text: 'รหัสลูกค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, false, false],
              },
              {
                text: `${manufacturingOrder?.customer?.type_code || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, true, true, false],
              },
              {
                text: 'เลขที่เอกสาร',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, false, false],
              },
              {
                text: `AI${manufacturingOrder?.running_number || ''}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, true, true, false],
              },
            ],
            [
              {
                text: 'ลูกค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${manufacturingOrder?.customer?.name || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
              {
                text: 'วันที่',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${dayjs(manufacturingOrder?.deposit?.deposit_date)
                  .locale('th')
                  .format(
                    information?.setting?.mms?.timing?.showTime
                      ? 'DD MMM BBBB เวลา HH.mm น.'
                      : 'DD MMM BBBB',
                  )}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'ที่อยู่',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `${manufacturingOrder?.customer?.address || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
              {
                text: 'เครดิต',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: `0 วัน จนถึง ${dayjs(
                  manufacturingOrder?.deposit?.deposit_date,
                ).format('DD MMM BBBB')}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `โทร ${
                  manufacturingOrder?.customer?.phone_number || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, true],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: '',
                colSpan: 1,
                fontSize: 10,
                border: [false, false, true, true],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 20, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['10%', '*', '20%', '20%'],

          heights: (row) => {
            console.log('Row', row);
            if (row === 0) return 15;

            if (row > _.size(manufacturingOrder?.process)) {
              if (information?.setting?.mms?.manufacturingOrder?.showPrice) {
                return 15;
              }
              return 1;
            }

            return 40;
          },
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการ / รายละเอียด',
                colSpan: 2,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {},
              {
                text: 'จำนวนเงิน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...orderData,
            [
              {
                text: '',
                border: [true, false, false, false],
                fontSize: 10,
                alignment: 'center',
                colSpan: 2,
              },
              {},
              {
                text: 'รวมเป็นเงิน',
                border: [true, false, true, false],
                fontSize: 10,
                alignment: 'left',
              },
              {
                text: `${currencyFormatterTH.format(totalPrice) || ''}`,
                border: [true, false, true, true],
                alignment: 'right',
                fontSize: 10,
                colSpan: 1,
              },
            ],
            [
              {
                text: '',
                border: [true, false, false, true],
                fontSize: 10,
                alignment: 'center',
                colSpan: 2,
              },
              {},
              {
                text: 'จำนวนภาษีมูลค่าเพิ่ม',
                border: [true, false, false, true],
                fontSize: 10,
                alignment: 'left',
              },
              {
                text: `${
                  currencyFormatterTH.format((totalPrice * 7) / 100) || ''
                }`,
                border: [true, false, true, true],
                alignment: 'right',
                fontSize: 10,
              },
            ],
            [
              {
                text: '',
                border: [true, false, false, true],
                fontSize: 10,
                alignment: 'left',
              },
              {
                text: `( ${THBText(totalPrice)} )`,
                border: [false, false, true, true],
                fontSize: 10,
                alignment: 'left',
              },
              {
                text: 'มูลค่าสินค้า',
                border: [false, false, false, true],
                fontSize: 10,
                alignment: 'left',
              },
              {
                text: `${currencyFormatterTH.format(totalPrice)}`,
                border: [true, false, true, true],
                alignment: 'right',
                fontSize: 10,
              },
            ],
          ],
        },
      },
      {
        style: 'tableExample',
        table: {
          widths: ['20%', '10%', '40%', '20%', '10%'],
          margin: [0, 30, 0, 10],
          heights: [15],
          body: [
            [
              { border: [false, false, false, false], text: '', colSpan: 5 },
              {},
              {},
              {},
              {},
            ],
            [
              {
                text:
                  'ใบรับเงินมัดจำฉบับนี้จะสมบูรณ์ต่อเมื่อมีลายเซ้นผู้มีอำนาจและลายเซ็นผู้รับเงิน และได้เรียกเก็บเงินตามเช็คเรียบร้อยแล้ว',
                colSpan: 2,
                fontSize: 9,
                rowSpan: 3,
                alignment: 'center',
                border: [true, true, true, false],
                margin: [0, 5, 0, 0],
              },
              {},
              {
                text: 'ชำระโดย ⏹️เงินสด  ⏹️เช็ค',
                border: [true, true, true, false],
                margin: [0, 5, 0, 0],
              },
              {
                text: `ในนาม ${information?.owner?.name || ''}`,
                colSpan: 2,
                fontSize: 10,
                alignment: 'center',
                border: [true, true, true, false],
                margin: [0, 5, 0, 0],
              },
              {},
            ],
            [
              {},
              {},
              {
                text:
                  'เช็คธนาคาร ........................... สาขา .....................',
                colSpan: 1,
                border: [true, false, true, false],
              },
              {
                text: '',
                bold: true,
                alignment: 'center',
                colSpan: 2,
                border: [true, false, true, false],
              },
              {},
            ],
            [
              {},
              {},
              {
                text:
                  'เลขที่ ........................ วันที่ ......./........./........',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                border: [true, false, true, false],
                text: '',
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: '........................................',
                colSpan: 2,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                margin: [0, 0, 0, 0],
                border: [true, false, true, false],
              },
              {},
              {
                text:
                  'จำนวนเงิน .................................................',
                colSpan: 1,
                border: [true, false, true, false],
              },
              {
                text: '........................................',
                border: [true, false, true, false],
                colSpan: 2,
                alignment: 'center',
              },
              {},
            ],
            [
              {
                text: '(........................................)',
                colSpan: 2,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, false, true, false],
              },

              {},
              {
                text:
                  'ภาษีหัก ณ ที่จ่าย ..........................................',
                colSpan: 1,
                border: [true, false, true, false],
              },
              {
                text: 'ผู้รับมอบอำนาจ',
                colSpan: 2,
                fontSize: 10,
                alignment: 'center',
                border: [true, false, true, false],
              },
              {},
            ],
            [
              {
                text: 'ผู้รับเงิน',
                colSpan: 2,
                bold: true,
                fontSize: 10,
                alignment: 'center',
                border: [true, false, true, false],
              },
              {},
              {
                text:
                  '..........................            ......./......./........',
                colSpan: 1,
                alignment: 'center',
                border: [true, false, true, false],
              },
              {
                text: '........................................',
                // `(
                // ${
                //   information?.setting?.spm?.quotation?.display_sales_name
                //     ? `${order?.sales?.firstname || ''} ${
                //         order?.sales?.lastname || ''
                //       }`
                //     : '........................................'
                // } )`

                colSpan: 2,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, true, false],
              },
              {},
            ],
            [
              {
                text: '',
                colSpan: 2,
                fontSize: 10,
                alignment: 'center',
                border: [true, false, false, true],
              },
              {},
              {
                text: 'ผู้รับเงิน                              วันที่',
                colSpan: 1,
                alignment: 'center',
                border: [true, false, true, true],
              },
              {
                text: 'ผู้ตรวจสอบ',
                colSpan: 2,
                fontSize: 10,
                alignment: 'center',
                border: [false, false, true, true],
              },
              {},
            ],
          ],
        },
      },
    ],
    // images: {
    //   logo: information?.logo?.url,
    // },
  };
  console.log('doc definition', docDefinition);
  try {
    const newDocDefinition = docDefinition;
    if (!pictureError) {
      newDocDefinition.images = {
        logo: information?.logo?.url,
      };
    }
    pdfMake.createPdf(newDocDefinition).open();
  } catch (error) {
    pdfMake.createPdf(docDefinition).open();
  }
};

export default DepositReport;
