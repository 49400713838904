import { BaseAction } from '../../class';
import {
  SHIPPING_ROUND_ALL,
  SHIPPING_ROUND_DELETE,
  SHIPPING_ROUND_ERROR,
  SHIPPING_ROUND_GET,
  SHIPPING_ROUND_LOADING,
  SHIPPING_ROUND_POST,
  SHIPPING_ROUND_PUT,
} from '../types';

const shippingRoundAction = new BaseAction('shipping-round', {
  allConst: SHIPPING_ROUND_ALL,
  getConst: SHIPPING_ROUND_GET,
  postConst: SHIPPING_ROUND_POST,
  putConst: SHIPPING_ROUND_PUT,
  deleteConst: SHIPPING_ROUND_DELETE,
  errorConst: SHIPPING_ROUND_ERROR,
  loadingConst: SHIPPING_ROUND_LOADING,
});

export const shippingRoundCreate = (data) => shippingRoundAction.create(data);
export const shippingRoundAll = ({ name, page, size, ...query }) =>
  shippingRoundAction.getAllData({ name, page, size, ...query });
export const shippingRoundGet = (id) => shippingRoundAction.getOneData(id);
export const shippingRoundPut = (id, data) =>
  shippingRoundAction.editOne(id, data);
export const shippingRoundDelete = (id) => shippingRoundAction.deleteOne(id);
export const shippingRoundStateSet = (data) =>
  shippingRoundAction.stateSet(data);
export const shippingRoundStateOneSet = (data) =>
  shippingRoundAction.stateOneSet(data);
export const shippingRoundReset = () => shippingRoundAction.reset();
export const shippingRoundError = () => shippingRoundAction.onError();
