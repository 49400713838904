import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Chip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

function MaterialStock({ title, subtitle }) {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.placeAll(''));
    dispatch(actions.materialTransactionTypeAll({}));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const filteredPlace = _.filter(
    place.rows,
    (eachPlace) =>
      eachPlace?.allow_all_department ||
      _.includes(
        _.map(eachPlace?.allow_departments, (each) => each._id),
        me?.userData?.department?._id,
      ),
  );

  const showAllPlace = place?.rows?.length === filteredPlace?.length;

  const renderPlace = () => (
    <div className="flex flex-wrap py-4">
      {showAllPlace && (
        <div
          className="w-full lg:4/12 xl:w-2/12 p-2 cursor-pointer"
          onClick={() => {
            history.push('/ims/material-stock/place/');
          }}
        >
          <div className="items-center text-center  p-4 bg-white rounded-lg  shadow-xl">
            <i className="fas fa-boxes fa-3x text-gray-500" />
            <div className="text-base my-1 font-display">รวมทุกคลัง</div>
          </div>
        </div>
      )}
      {!_.isEmpty(filteredPlace) ? (
        filteredPlace.map((row, index) => (
          <div
            key={index}
            className="w-full lg:4/12 xl:w-2/12 p-2 cursor-pointer"
            onClick={() => {
              history.push(`/ims/material-stock/place/${row.id}`);
            }}
          >
            <div className="items-center text-center  p-4 bg-white rounded-lg  shadow-xl">
              <i className="fas fa-boxes fa-3x text-gray-500" />
              <div className="text-base my-1 font-display">
                {row.name}{' '}
                {row?.wip_place && (
                  <Chip label="WIP" color="warning" size="small" />
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="flex justify-center p-4">
          <div>ไม่มีข้อมูล</div>
        </div>
      )}
    </div>
  );

  if (place.isLoading) {
    return <Loading />;
  }
  if (!place.isLoading && place.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="flex gap-2 self-center">
            {_.map(
              _.orderBy(materialTransactionType?.rows, ['direction']),
              (eachType, index) => (
                <Link
                  key={index}
                  to={`/ims/material-stock/${
                    eachType?.direction === 'add' ? 'create' : 'withdraw'
                  }?txnType=${eachType?._id}`}
                >
                  <Button
                    variant="contained"
                    color={eachType?.direction === 'add' ? 'success' : 'error'}
                  >
                    {eachType?.name}{' '}
                  </Button>
                </Link>
              ),
            )}
            {information?.setting?.ims?.viyacrabSpecific && (
              <Link to="/ims/viyacrab/upload-material-stock">
                <Button variant="contained" color="primary">
                  อัพโหลดผ่าน Excel
                </Button>
              </Link>
            )}
          </div>
        </div>
        {renderPlace()}
      </div>
    );
  }
  return <Error message={place?.message} />;
}

MaterialStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialStock.defaultProps = {
  title: '',
  subtitle: '',
};

export default MaterialStock;
