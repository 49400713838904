import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  Loading,
  MainFooter,
  NotAuthorized,
  NotFound,
} from '@iarcpsu/emanufac-components/src/components';
import PropTypes from 'prop-types';

import WordLocalizeProvider from '../contexts/WordLocalizeContext';
import * as actions from '../redux/actions';
import accessRight from '../utils/functions/accessRight';
import {
  CostEstimation,
  Deprecated,
  Material,
  MaterialStockLot,
  Operation,
  Report,
  Setting,
  Viyacrab,
} from '../views/IMS';

// TODO: เรายังไม่ได้ Level  Access Control นะ
export function IMS({
  prefix = '/ims',
  name = 'ตั้งค่าระบบ',
  module = 'IMS',
  MainSidebar,
  HomeNavbar,
}) {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: IMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <WordLocalizeProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>คลังวัตถุดิบ - {information?.name || 'E-Manufac'}</title>
          </Helmet>
          <MainSidebar
            onMobileClose={handleOnMobileNavClose}
            openMobile={isMobileNavOpen}
            me={me}
            isTaskbarOpen={sidebarOpen}
            setIsTaskbarOpen={setSidebarOpen}
          />
          <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
          <div
            className={`${
              sidebarOpen ? 'lg:ml-64' : ''
            }   min-h-screen  pt-16 px-8 `}
          >
            <div className="py-4">
              <Switch>
                <Redirect
                  exact
                  from={`${prefix}`}
                  to={`${prefix}/material-stock`}
                />
                <Redirect
                  from="/:url*(/+)"
                  to={`${pathname.slice(0, -1)}${search || ''}`}
                />
                <Route exact path={`${prefix}/material-stock`}>
                  <MaterialStockLot.MaterialStock
                    title="จัดการสต๊อกวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock/place/:id`}>
                  <MaterialStockLot.DetailMaterialStock
                    title="จัดการสต๊อกวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock/place`}>
                  <MaterialStockLot.DetailMaterialStock
                    title="จัดการสต๊อกวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route
                  exact
                  path={`${prefix}/material-stock/create/legacy/:id`}
                >
                  <Deprecated.LegacyCreateMaterialStockLot
                    title="เพิ่มล็อตวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock/create/:id`}>
                  <MaterialStockLot.CreateMaterialStockLot
                    title="เพิ่มล็อตวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock/create`}>
                  <MaterialStockLot.CreateMaterialStockLot
                    title="เพิ่มล็อตวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock/withdraw/:id`}>
                  <MaterialStockLot.WithdrawMaterialStockLot
                    title="เบิกวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock/withdraw`}>
                  <MaterialStockLot.WithdrawMaterialStockLot
                    title="เบิกวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock/edit/:id`}>
                  <MaterialStockLot.EditMaterialStockLot
                    title="แก้ไขล็อตวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock/:id/:placeId`}>
                  <MaterialStockLot.DetailMaterialStockLotOfMaterial
                    title="รายการล็อตวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/many-material-lots`}>
                  <MaterialStockLot.ManyMaterialLots
                    title="รายการล็อตวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock/group`}>
                  <MaterialStockLot.MaterialStockByGroup
                    title="สต็อกวัตถุดิบแบบจัดกลุ่ม"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/material-stock/group-clickable`}>
                  <MaterialStockLot.MaterialByGroupWithClickableTable
                    title="สต็อกวัตถุดิบแบบจัดกลุ่ม"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route
                  exact
                  path={`${prefix}/material-stock/transfer/success/:id`}
                >
                  <Operation.SuccessTransfer
                    title="ย้ายวัตถุดิบสำเร็จ"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/material-stock/move/success/:id`}>
                  <Operation.SuccessMoveMaterial
                    title="ย้ายวัตถุดิบสำเร็จ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock/:id/`}>
                  <MaterialStockLot.DetailMaterialStockLotOfMaterial
                    title="รายการล็อต"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/material-stock-lot/:id/`}>
                  <MaterialStockLot.DetailMaterialStockLot
                    title="รายละเอียดล็อตวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/material-stock-lot`}>
                  <MaterialStockLot.MaterialStockLotList
                    title="รายการล็อตวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock-lot/child/:id`}>
                  <MaterialStockLot.MaterialChildLots
                    title="รายการล็อตวัตถุดิบผลผลิต"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-stock-to-product`}>
                  <MaterialStockLot.MaterialStockToProduct
                    title="แปลงจากวัตถุดิบเป็นสินค้า"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/materials`}>
                  <Material.Material
                    title="จัดการข้อมูลวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material/edit/:id`}>
                  <Material.EditMaterial
                    title="แก้ไขข้อมูลวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/materials/create`}>
                  <Material.CreateMaterial
                    title="เพิ่มวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/materials/create-multiple`}>
                  <Material.CreateMultipleMaterial
                    title="เพิ่มวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/materials/import`}>
                  <Material.ImportExcelMaterial
                    title="อัพโหลดข้อมูลวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/materials/labor-cost`}>
                  <CostEstimation.MaterialLumpSumLaborCost
                    title="ต้นทุนค่าแรงต่อหน่วยวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/materials/labor-cost/edit`}>
                  <CostEstimation.EditMaterialLumpSumLaborCost
                    title="แก้ไขต้นทุนค่าแรงต่อหน่วยวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/materials/ingredient/edit/:id`}>
                  <Material.EditMaterialIngredient
                    title="แก้ไขวัตถุดิบส่วนประกอบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material/:id`}>
                  <Material.DetailMaterial
                    title="รายละเอียดข้อมูลวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-cost`}>
                  <CostEstimation.MaterialCosts
                    title="ต้นทุนวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-cost/edit`}>
                  <MaterialStockLot.EditMaterialCost
                    title="แก้ไขต้นทุนวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-cost/daily`}>
                  <CostEstimation.DailyMaterialCost
                    title="ต้นทุนวัตถุดิบรายวัน"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/material-cost/daily/detail/:day`}>
                  <CostEstimation.DetailDailyMaterialCost
                    title="รายละเอียดต้นทุนวัตถุดิบรายวัน"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/history`}>
                  <MaterialStockLot.MaterialHistory
                    title="ประวัติการจัดการวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/history/detail/:id`}>
                  <MaterialStockLot.DetailMaterialTransaction
                    title="รายละเอียดการจัดการวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/history/edit/:id`}>
                  <MaterialStockLot.EditMaterialHistory
                    title="แก้ไขการจัดการวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/withdraw-order`}>
                  <Operation.MaterialWithdrawOrder
                    title="คำขอเบิกวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/withdraw-order/approval/:id`}>
                  <Operation.ApprovalMaterialWithdrawOrder
                    title="อนุมัติคำขอเบิกวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/withdraw-destination/`}>
                  <Material.WithdrawDestinations
                    title="เป้าหมายการเบิกวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/withdraw-destination/view/:id`}>
                  <Material.DetailWithdrawDestination
                    title="รายละเอียดเป้าหมายการเบิกวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-types`}>
                  <Material.MaterialType
                    title="ประเภทวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/material-types/edit/:id`}>
                  <Material.EditMaterialType
                    title="แก้ไขประเภทวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/place`}>
                  <Setting.Place title="รายการคลังวัตถุดิบ" subtitle={name} />
                </Route>
                <Route exact path={`${prefix}/place/edit/:id`}>
                  <Setting.EditPlace
                    title="แก้ไขคลังวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/move/:id`}>
                  <Operation.MoveMaterial
                    title="ย้ายวัตถุดิบจากคลัง"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/fixed-move/`}>
                  <Operation.FixedMoveMaterial
                    title="ย้ายวัตถุดิบจากคลัง"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/transform/:id`}>
                  <Operation.TransformMaterial
                    title="แปลงวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/fixed-transform`}>
                  <Operation.FixedTransformMaterial
                    title="แปลงวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/fixed-move-transform-warehouse/`}>
                  <Operation.FixedMoveAndTranformMaterialToProduct
                    title="แปลงวัตถุดิบเป็นสินค้า"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/confirm-material-to-product/`}>
                  <Operation.ConfirmLotTransferToProduct
                    title="ยืนยันจำนวนการแปลงวัตถุดิบเป็นสินค้า"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/edit-material-to-product/`}>
                  <Operation.EditLotTransferToProduct
                    title="แก้ไขจำนวนการแปลงวัตถุดิบเป็นสินค้า"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/product-preview/`}>
                  <Operation.ProductPreview
                    title="สินค้าที่คาดว่าจะได้รับ"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/cost-estimation/lots`}>
                  <CostEstimation.CostEstimationByLots
                    title="ประมาณการต้นทุนในล็อต"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/cost-estimation/process`}>
                  <CostEstimation.CostEstimationByProcess
                    title="ประมาณการต้นทุนตามไลน์การผลิต"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/cost-estimation/from-beginning`}>
                  <CostEstimation.BeginnerLots
                    title="รายการล็อตวัตถุดิบเริ่มต้น"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route
                  exact
                  path={`${prefix}/cost-estimation/from-beginning/detail/:id`}
                >
                  <CostEstimation.DetailCostEstimationOnBeginnerLot
                    title="รายละเอียดต้นทุนจากล็อตเริ่มต้น"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/cost-driver/process/:id`}>
                  <CostEstimation.DetailCostEstimationByProcess
                    title="รายละเอียดประมาณการต้นทุนตามไลน์การผลิต"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/cost-driver/weight-in-place/:id`}>
                  <CostEstimation.WeightAllowInPlace
                    title="วัตถุดิบในคลังในแต่ละวัน"
                    subtitle={name}
                  />
                </Route>
                <Route
                  exact
                  path={`${prefix}/cost-driver/capital-in-place/:id`}
                >
                  <CostEstimation.DetailFactoryCapitalDate
                    title="ต้นทุนการลงทุนในคลัง"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/cost-driver/:id`}>
                  <CostEstimation.MaterialCostDriverDisplay
                    title="รายละเอียดต้นทุนวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/time-duration`}>
                  <CostEstimation.MaterialLotTimeDuration
                    title="เวลาที่ใช้ในแต่ละล็อต"
                    subtitle={name}
                  />
                </Route>{' '}
                <Route exact path={`${prefix}/report-display`}>
                  <Report.ReportDisplay title="พิมพ์รายงาน" subtitle={name} />
                </Route>{' '}
                <Route exact path={`${prefix}/dashboard/`}>
                  <Report.MaterialReportDashboard
                    title="รายงานการใช้วัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/dashboard/summary`}>
                  <Report.MaterialReportDashboard
                    title="รายงานการใช้วัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route
                  exact
                  path={`${prefix}/dashboard/material-report-dashboard/detail`}
                >
                  <Report.DetailMaterialReportDashboard
                    title="รายงานการใช้วัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route exact path={`${prefix}/dashboard/inventory-warehouse`}>
                  <Report.InventoryAndWarehouseDashboard
                    title="สถานะคลังวัตถุดิบและสินค้า"
                    subtitle={name}
                  />
                </Route>
                {/** ******** VIYACRAB ************* */}
                <Route exact path={`${prefix}/viyacrab/upload-material-stock`}>
                  <Viyacrab.UploadMaterialStock
                    title="อัพโหลดสต็อกวัตถุดิบ"
                    subtitle={name}
                  />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </div>
          </div>
          <div className="lg:ml-64">
            <MainFooter />
          </div>
        </WordLocalizeProvider>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default IMS;

IMS.propTypes = {
  prefix: PropTypes.string,
  name: PropTypes.string,
  module: PropTypes.string,
  MainSidebar: PropTypes.object,
  HomeNavbar: PropTypes.object,
};

// import React from 'react';

// export default function IMS() {
//   return <div>Oh Yes Here,we go again</div>;
// }
